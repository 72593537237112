import React, { useState, useContext, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _ from 'underscore';
import { LineChart } from '@mui/x-charts/LineChart';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import { scaleLog } from 'd3-scale';
import { ResponsiveChartContainer } from '@mui/x-charts';
const scale = scaleLog().base(Math.E);



const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

  const units = [
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' seconds', yAxis: ' Pa.s'},
    {xAxis: ' seconds', yAxis: ' Pa.s'},
    {xAxis: ' %', yAxis: ' Pa'},
    {xAxis: ' seconds', yAxis: ' Pa'},
    {xAxis: ' rad/s', yAxis: ' Pa'},
    {xAxis: ' seconds', yAxis: ' Pa.s'},
    {xAxis: ' 1/s', yAxis: ' Pa.s'},
    {xAxis: ' seconds', yAxis: ' Pa'},
  ];

  const scales = [
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
    {xAxis: 'log', yAxis: 'log'},
    {xAxis: 'linear', yAxis: 'log'},
  ]

export default function RheobasePlotX(props) {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    
    const [ inkExpId, setInkExpId ] = useState();
    const [ rheobase, setRheobase ] = useState();
    const [ rheobases, setRheobases ] = useState([]);
    const [ rheobaseRows, setRheobaseRows ] = useState();

    const [ currentInterval, setCurrentInterval ] = useState(1);

    const [ xAxisDataKey, setXAxisDataKey ] = useState("angular_frequency");
    const [ yAxisDataKey1, setYAxisDataKey1 ] = useState("storage_modulus");
    const [ yAxisDataKey2, setYAxisDataKey2 ] = useState("loss_modulus");

    const [ interval1color, setInterval1Color ] = useState("primary");
    const [ interval2color, setInterval2Color ] = useState();
    const [ interval3color, setInterval3Color ] = useState();
    const [ interval4color, setInterval4Color ] = useState();
    const [ interval5color, setInterval5Color ] = useState();
    const [ interval6color, setInterval6Color ] = useState();
    const [ interval7color, setInterval7Color ] = useState();
    const [ interval8color, setInterval8Color ] = useState();
    const [ interval9color, setInterval9Color ] = useState();

    const [ xAxisScale, setXAxisScale ] = useState('log');
    const [ yAxisScale, setYAxisScale ] = useState('log');

    const [ xAxisUnit, setXAxisUnit ] = useState(' rad/s');
    const [ yAxisUnit, setYAxisUnit ] = useState(' Pa');

    const [xAxisReverse, setXAxisReverse ] = useState(true);
  
    const [ loading, setLoading ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
      const loadRheobase = async () => {
        // if (!rheobase && localStorage.getItem(props.bioExpId)) {
        try {
            setLoading(true);
            setInkExpId(localStorage.getItem("ink_exp_id"));
            const rheobaseRes = await axiosClient.get("rheobase/getRheobase", {params: {ink_exp_id: localStorage.getItem("ink_exp_id")}});
            setRheobase(rheobaseRes.data);
            rheobases.push(rheobaseRes.data);
            setCurrentInterval(1);
            const rheobaseRowss = await axiosClient.get("rheobase/getRheobaseRows", {params: {ink_exp_id: localStorage.getItem("ink_exp_id"), interval_no: 1}});
            setRheobaseRows(rheobaseRowss.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      // }
      };  
      loadRheobase();
      setLoaded(true);
    }, []);

    const resetIntervalChips = () => {
      setInterval1Color("default");
      setInterval2Color("default");
      setInterval3Color("default");
      setInterval4Color("default");
      setInterval5Color("default");
      setInterval6Color("default");
      setInterval7Color("default");
      setInterval8Color("default");
      setInterval9Color("default");
    }

    const xAxisDataKeys = ["", "angular_frequency", "interval_time", "interval_time", "shear_strain"   , "interval_time"  , "angular_frequency", "interval_time", "shear_rate", "interval_time" ];
    const yAxisDataKey1s = ["", "storage_modulus" , "viscosity"    , "viscosity"    , "storage_modulus", "storage_modulus", "storage_modulus"  , "viscosity"    , "viscosity" , "storage_modulus"];
    const yAxisDataKey2s = ["", "loss_modulus"    , ""             , ""             , "loss_modulus"   , "loss_modulus"   , "loss_modulus"     , ""             , ""          , "loss_modulus"];

    const handleClick = async (e, interval) => {
      console.info('You clicked the Interval ', interval);
      setLoading(true);
      setCurrentInterval(interval);
      setXAxisDataKey(xAxisDataKeys[interval]);
      setYAxisDataKey1(yAxisDataKey1s[interval]);
      if (yAxisDataKey2s[interval] === "") {
        setYAxisDataKey2(yAxisDataKey1s[interval]);
      }
      else {
        setYAxisDataKey2(yAxisDataKey2s[interval]);
      }
      if (interval === 1 || interval === 6) {
        setXAxisReverse(true)
      } else {
        setXAxisReverse(false)
      }
      setXAxisScale(scales[interval].xAxis);
      setYAxisScale(scales[interval].yAxis);
      setXAxisUnit(units[interval].xAxis);
      setYAxisUnit(units[interval].yAxis);
      const rheobaseRowss = await axiosClient.get("rheobase/getRheobaseRows", {params: {ink_exp_id: localStorage.getItem("ink_exp_id"), interval_no: interval}});
      setRheobaseRows(rheobaseRowss.data);
      setLoading(false);
    };

    const downloadCsv = async () => {
      window.open(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/rheobase/"+inkExpId+"--"+rheobase.csv_file);
    };

    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <div style={{margin: 20}}>
                <h1>Rheobase Experiment</h1>
                <LoadingBackdrop open={loading} />
                  <Grid container maxWidth="xl">
                    <Grid item xs={3}>
                    <Button variant="contained" color="primary" onClick={(e) => downloadCsv(e)} fullWidth={true} >Download CSV File</Button>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        {/* <TableHead>
                          <TableRow>
                            <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Calories</TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableBody>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Experiment ID:
                              </TableCell>
                              <TableCell align="right">{inkExpId}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Ink ID:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.ink_id : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Name:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.name : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Date:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.date : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Location:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.location : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Device:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.device : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Geometry:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.geometry : "----"}</TableCell>
                            </TableRow>
                            <TableRow key="key">
                              <TableCell component="th" scope="row">
                                Notes:
                              </TableCell>
                              <TableCell align="right">{rheobase? rheobase.notes : "----"}</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </Grid>
                    <Grid item xs={9}>
                    <Container component="main">
                      <Grid container spacing={2}>
                        <Grid item>
                          <Chip avatar={<Avatar>1</Avatar>} label="Frequency sweep" color={interval1color} onClick={(e) => {resetIntervalChips(); setInterval1Color("primary"); handleClick(e,1)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>2</Avatar>} label="Preconditioning" color={interval2color} onClick={(e) => {resetIntervalChips(); setInterval2Color("primary"); handleClick(e,2)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>3</Avatar>} label="Resting step" color={interval3color} onClick={(e) => {resetIntervalChips(); setInterval3Color("primary"); handleClick(e,3)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>4</Avatar>} label="Amplitude sweep" color={interval4color} onClick={(e) => {resetIntervalChips(); setInterval4Color("primary"); handleClick(e,4)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>5</Avatar>} label="1st recovery" color={interval5color} onClick={(e) => {resetIntervalChips(); setInterval5Color("primary"); handleClick(e,5)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>6</Avatar>} label="Frequency sweep" color={interval6color} onClick={(e) => {resetIntervalChips(); setInterval6Color("primary"); handleClick(e,6)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>7</Avatar>} label="Resting step" color={interval7color} onClick={(e) => {resetIntervalChips(); setInterval7Color("primary"); handleClick(e,7)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>8</Avatar>} label="Flow point" color={interval8color} onClick={(e) => {resetIntervalChips(); setInterval8Color("primary"); handleClick(e,8)}} />
                        </Grid>
                        <Grid item>
                          <Chip avatar={<Avatar>9</Avatar>} label="2nd recovery" color={interval9color} onClick={(e) => {resetIntervalChips(); setInterval9Color("primary"); handleClick(e,9)}} />
                        </Grid>
                        <Grid item>
                          <Checkbox checked={xAxisReverse} onChange={(e) => setXAxisReverse(!xAxisReverse)}></Checkbox>Reverse X Axis
                        </Grid>
                      </Grid>
                      {rheobaseRows ? 
                      <LineChart
                        xAxis={[{ dataKey: xAxisDataKey, scaleType: xAxisScale, label: xAxisUnit, reverse: xAxisReverse }]}
                        yAxis={[{ id: 'yAxisScale', scaleType: yAxisScale, label: yAxisUnit}]}
                        series={[
                          {
                          dataKey: yAxisDataKey1,
                          label: yAxisDataKey1
                          },
                          {
                          dataKey: yAxisDataKey2,
                          label: yAxisDataKey2
                          }
                        ]}
                        dataset={rheobaseRows}
                        height={700}
                        width={1100}
                        grid={{ vertical: true, horizontal: true }}
                        leftAxis= 'yAxisScale'
                    />: <></>
                    }
                  </Container>
                  </Grid>
                  </Grid>
              </div>
        )
        }
        </>
    );
}
