import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from "axios";
import Header from "./components/layouts/Header";
import Home from './components/pages/Home';
import Login from './components/auth/Login';
import CreateUser from './components/auth/CreateUser';
import UserContext from './context/UserContext';
import Dashboard from "./components/pages/Dashboard";
import PrintbaseInput from "./components/pages/PrintbaseInput";
import PrintbaseEvaluation from "./components/pages/PrintbaseEvaluation";
import RheobaseInput from "./components/pages/RheobaseInput";
import PrintbaseExperimentPage from "./components/pages/PrintbaseExperimentPage";
import PrintbaseExperimentCompare from "./components/pages/PrintbaseExperimentCompare";
import PrintbaseUpdatePage from "./components/pages/PrintbaseUpdatePage";
import Footer from "./components/layouts/Footer";
import PrintbaseEdit from "./components/pages/PrintbaseEdit";
import BiobaseInput from "./components/pages/BiobaseInput";
import ChangePassword from "./components/auth/ChangePassword";
import BiobaseEvaluation from "./components/pages/BiobaseEvaluation";
import BiobaseExperimentPage from "./components/pages/BiobaseExperimentPage";
import BiobaseExperimentCompare from "./components/pages/BiobaseExperimentCompare";
import BiobaseUpdatePage from "./components/pages/BiobaseUpdatePage";
import BiobaseEdit from "./components/pages/BiobaseEdit";
import CreateNewPassword from "./components/auth/CreateNewPassword";
import RheobaseEvaluation from "./components/pages/RheobaseEvaluation";
import RheobasePlot from "./components/pages/RheobasePlot";
import InkbaseInput from "./components/pages/InkBaseInput";
import ApproveInkbase from "./components/pages/ApproveInkbase";
import InkbaseView from "./components/pages/InkBaseView";
import InkbaseUpdate from "./components/pages/InkbaseUpdate";
import InkbaseEdit from "./components/pages/InkbaseEdit";
import RheobaseComparison from "./components/pages/RheobaseComparison";
import RheobaseUpdate from "./components/pages/RheobaseUpdate";
import PreDashboard from "./components/pages/PreDashboard";
import InternalDocuments from "./components/pages/InternalDocuments";
import InkbaseDocuments from "./components/pages/internalDocuments/InkbaseDocuments";
import RheobaseDocuments from "./components/pages/internalDocuments/RheobaseDocuments";
import PrintbaseDocuments from "./components/pages/internalDocuments/PrintbaseDocuments";
import BiobaseDocuments from "./components/pages/internalDocuments/BiobaseDocuments";
import RheobasePlotX from "./components/pages/RheobasePlotX";
import RheobaseComparisonX from "./components/pages/RheobaseComparisonX";

function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    firstname: undefined,
    role: undefined
  });
  
  useEffect(() => {
    const checkLoginStatus = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/tokenIsValid`, null, { headers: { "x-auth-token": token}});
      if (tokenRes.data) {
        const getUserRes = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/getUser`, { headers: { "x-auth-token": token}});
        setUserData({ 
          token,
          user: getUserRes.data.userId,
          firstname: getUserRes.data.firstname,
          role: getUserRes.data.role
        });
      }
    };
    checkLoginStatus();
  }, []);

  return (
    <>
    
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/createUser" component={CreateUser} />
              <Route path="/changePassword" component={ChangePassword} />
              <Route path="/createNewPassword" component={CreateNewPassword} />
              <Route path="/preDashboard" component={PreDashboard} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/printbaseInput" component={PrintbaseInput} />
              <Route path="/printbaseUpdate" component={PrintbaseUpdatePage} />
              <Route path="/printbaseEdit" component={PrintbaseEdit} />
              <Route path="/printbaseEvaluation" component={PrintbaseEvaluation} />
              <Route path="/printbaseExperiment" component={PrintbaseExperimentPage} />
              <Route path="/printbaseCompare" component={PrintbaseExperimentCompare} />
              <Route path="/biobaseInput" component={BiobaseInput} />
              <Route path="/biobaseUpdate" component={BiobaseUpdatePage} />
              <Route path="/biobaseEdit" component={BiobaseEdit} />
              <Route path="/biobaseEvaluation" component={BiobaseEvaluation} />
              <Route path="/biobaseExperiment" component={BiobaseExperimentPage} />
              <Route path="/biobaseCompare" component={BiobaseExperimentCompare} />
              <Route path="/rheobaseInput" component={RheobaseInput} />
              <Route path="/rheobaseEvaluation" component={RheobaseEvaluation} />
              <Route path="/rheobasePlot" component={RheobasePlotX} />
              <Route path="/rheobaseComparison" component={RheobaseComparisonX} />
              <Route path="/rheobaseUpdate" component={RheobaseUpdate} />
              <Route path="/inkbaseInput" component={InkbaseInput} />
              <Route path="/approveInkbase" component={ApproveInkbase} />
              <Route path="/inkbaseView" component={InkbaseView} />
              <Route path="/inkbaseUpdate" component={InkbaseUpdate} />
              <Route path="/inkbaseEdit" component={InkbaseEdit} />
              <Route path="/internalDocuments" component={InternalDocuments} />
              <Route path="/inkbaseDocuments" component={InkbaseDocuments} />
              <Route path="/rheobaseDocuments" component={RheobaseDocuments} />
              <Route path="/printbaseDocuments" component={PrintbaseDocuments} />
              <Route path="/biobaseDocuments" component={BiobaseDocuments} />
            </Switch>
          <Footer />
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
