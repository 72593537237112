import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import UserContext from "../../context/UserContext";
import { parse } from "papaparse";

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
    
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    preview: {
        display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    }
  }));

export default function InkbaseInput() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ inkbase, setInkbase ] = useState();
    const [ ink_id, setInk_id ] = useState();
    const [ category, setCategory ] = useState();
    const [ composition, setComposition ] = useState();
    const [ sopFileName, setSopFileName ] = useState();
    const [ sopFile, setSopFile ] = useState([]);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();
    const [ reviewVisibility, setReviewVisibility ] = useState("hidden");

    const [ ink_id_disabled, setInk_id_disabled ] = useState(false);

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);


    const validated = () => {
        if (!ink_id || !category || !composition) {
            setSeverity("error");
            setNotification("Please fill in all mandatory fields.");
            return false;
        }
        return true;
    };
    
    const handleDataChange = async (newFiles) => {
        setLoading(true);
        const file = newFiles[0];
        if (file) {
            const inkbaseExistsRes = await axiosClient.get("inkbase/checkInkbase", {params: {ink_id: ink_id}})
            .then((response) => {
                if (response.data.status === "exists") {
                    setSeverity("error");
                    setNotification("Ink already exists or has been already requested.");
                    setLoading(true);
                    sleep(7000).then(()=>{
                        window.location.reload(true);
                    });
                    return;
                }
            })
            .catch((error) => {
                setSeverity("error");
                setNotification("Something went wrong.");
                setLoading(true);
                sleep(7000).then(()=>{
                    window.location.reload(true);
                });
                return;
            });
            const newFile=new File([file], ink_id.replace(/\s+/g, '_')+"_SOP.pdf"); 
            setSopFileName(ink_id.replace(/\s+/g, '_')+"_SOP.pdf");
            setSopFile([newFile]);
            setInk_id_disabled(true);
        }
        setLoading(false);
    };

    const handleDataDelete = (deletedFile) => {
        setInk_id_disabled(false);
        setSopFile(undefined);
        setSopFileName(undefined);
    };

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                
                const inkbaseInput = new FormData();
                if(sopFile[0]) {inkbaseInput.append("images", sopFile[0]);}
                inkbaseInput.append("ink_id", ink_id);
                inkbaseInput.append("category", category);
                inkbaseInput.append("composition", composition);
                inkbaseInput.append("sop_file", sopFileName);
                const inkbaseInputRes = await axiosClient.post("inkbase/requestInkbase", inkbaseInput);
                setLoading(false);
                setSeverity("success");
                setNotification(inkbaseInputRes.data.responseMessage);
                sleep(5000).then(()=>{
                    history.push("/dashboard");
                });
            } catch (err) {
                setSubmitDisabled(false);
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        }
    };

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (<>   
                    <LoadingBackdrop open={loading} />
                    <Container component="main" maxWidth="md">
                        <h1>Inkbase Data Input</h1>
                        <div>
                            * Please generate an Ink Name from this {/* {React.createElement('a', {href:'https://docs.google.com/spreadsheets/d/1VxpMrSNDMmIWjo8r6y6Po8TbcYp92KaLOBhgyFWWfjI/edit#gid=0'}, 'Google Sheet')} */} 
                            <Button color='primary' variant="contained" role='link' onClick={() => window.open("https://docs.google.com/spreadsheets/d/1VxpMrSNDMmIWjo8r6y6Po8TbcYp92KaLOBhgyFWWfjI/edit#gid=0", "_blank", "noreferrer")} >Google Sheet</Button>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                disabled={ink_id_disabled}
                                id="ink_id"
                                label="Ink Name"
                                name="ink_id"
                                onChange={(e) => setInk_id(e.target.value)}
                        />
                        <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="category_label">Category</InputLabel>
                                <Select
                                    labelId="Category"
                                    required
                                    fullWidth
                                    id="category"
                                    value={category}
                                    onChange={(e) => {setCategory(e.target.value)}}
                                    >
                                    <MenuItem key={"ADA based"} value={"ADA based"}>ADA based</MenuItem>
                                    <MenuItem key={"Alginate based"} value={"Alginate based"}>Alginate based</MenuItem>
                                    <MenuItem key={"ECM based"} value={"ECM based"}>ECM based</MenuItem>
                                    <MenuItem key={"Gelatine based"} value={"Gelatine based"}>Gelatine based</MenuItem>
                                    <MenuItem key={"Hyaluronic acid based"} value={"Hyaluronic acid based"}>Hyaluronic acid based</MenuItem>
                                    <MenuItem key={"PEG based"} value={"PEG based"}>PEG based</MenuItem>
                                    <MenuItem key={"POx based"} value={"POx based"}>POx based</MenuItem>
                                    <MenuItem key={"Silk based"} value={"Silk based"}>Silk based</MenuItem>
                                    <MenuItem key={"Others"} value={"Others"}>Others</MenuItem>
                                </Select>
                            </FormControl>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="composition"
                                label="Composition"
                                name="composition"
                                onChange={(e) => setComposition(e.target.value)}
                        />
                        <DropzoneArea
                            acceptedFiles={["application/pdf",]}
                            showFileNamesInPreview={true}
                            filesLimit={1}
                            onChange={handleDataChange}
                            onDelete={handleDataDelete}
                            useChipsForPreview={true}
                            dropzoneText="Click to upload or Drag a .pdf file here"
                            showAlerts={false}
                        />
                        </div>
                        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={submitDisabled}
                            >
                                Request for approval
                            </Button>
                        </form>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>

                </>)
            }
        </>
    )
}