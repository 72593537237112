import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import UserContext from "../../context/UserContext";
import { parse } from "papaparse";

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#005896",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    preview: {
        display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    }
  }));


  const gridProps = {
    container: {
        
    },
    item: {
        xs: 8,
    }
  };


export default function PrintbaseInput() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        loadAllInks();
    }, []);

    const loadAllInks = async () => {
        try {
            setLoading(true);
            const inkbasesRes = await axiosClient.get("inkbase/getAll");
            setBioinks(inkbasesRes.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    }

    const [ printbase, setPrintbase ] = useState();
    const [ dataFile, setDataFile ] = useState([]);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();
    const [ reviewVisibility, setReviewVisibility ] = useState("hidden");
    const [ bioink, setBioink ] = useState();
    const [ bioinks, setBioinks ] = useState([]);

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ fileData, setFileData ] = useState({
        userId: userData.user,
        print_exp_id: undefined,
        phd_id: undefined,
        lab_id: undefined,
        date: undefined,
        bioink_id: undefined,
        compos_text: undefined,
        print_notes: undefined,
        printer_id: undefined,
        nozzle: undefined,
        substrate: undefined,
        temp_p_1: undefined,
        temp_p_2: undefined,
        temp_p_3: undefined,
        pressure_p_1: undefined,
        pressure_p_2: undefined,
        pressure_p_3: undefined,
        inktype_1: undefined,
        inktype_2: undefined,
        inktype_3: undefined,
        mass_1: undefined,
        mass_2: undefined,
        mass_3: undefined,
        dcr_1: undefined,
        dcr_2: undefined,
        dcr_3: undefined,
        dcr_4: undefined,
        sw_1: undefined,
        sw_2: undefined,
        sw_3: undefined,
        filam_1: undefined,
        filam_2: undefined,
        filam_3: undefined,
        csv_file: "",
        fft_1: "",
        fft_2: "",
        fft_3: "",
        gst_o: "",
        gst_1: "",
        gst_2: "",
        gst_3: "",
        gst_4: "",
        sst_1: "",
        sst_2: "",
        sst_3: "",
        fct_1: "",
        fct_2: "",
        fct_3: "",
        fct_4: "",
        fct_5: "",
    });

    const validated = () => {
        if(!fileData.print_exp_id || !fileData.phd_id || !fileData.lab_id || !fileData.date /* || !fileData.bioink_id */){
            setSeverity("error");
            setNotification("Please fill in all fields.");
            return false;
        }
        if (!bioink) {
            setSeverity("error");
            setNotification("Please select a Bioink.");
            return false;
        }
        return true;
    }
    
    const handleDataChange = async (newFiles) => {
        loadAllInks();
        const file = newFiles[0];
        if (file) {
            const text = await file.text();
            const parsed = parse(text);
           
            const fileData = {
                userId: userData.user,
                print_exp_id: parsed.data[3][6],
                phd_id: parsed.data[5][6],
                lab_id: parsed.data[7][6],
                date: parsed.data[9][6],
                /* bioink_id: parsed.data[11][6], */
                compos_text: parsed.data[13][6],
                print_notes: parsed.data[15][6],
                printer_id: parsed.data[17][6],
                nozzle: parsed.data[18][6],
                substrate: parsed.data[19][6],
                temp_p_1: parsed.data[21][6],
                temp_p_2: parsed.data[21][8],
                temp_p_3: parsed.data[21][10],
                pressure_p_1: parsed.data[22][6],
                pressure_p_2: parsed.data[22][8],
                pressure_p_3: parsed.data[22][10],
                inktype_1: parsed.data[23][6],
                inktype_2: parsed.data[23][8],
                inktype_3: parsed.data[23][10],
                mass_1: parsed.data[24][6],
                mass_2: parsed.data[24][8],
                mass_3: parsed.data[24][10],
                dcr_1: parsed.data[26][6],
                dcr_2: parsed.data[26][8],
                dcr_3: parsed.data[26][10],
                dcr_4: parsed.data[26][12],
                sw_1: parsed.data[28][6],
                sw_2: parsed.data[28][8],
                sw_3: parsed.data[28][10],
                filam_1: parsed.data[30][6],
                filam_2: parsed.data[30][8],
                filam_3: parsed.data[30][10],
                csv_file: "",
                fft_1: "",
                fft_2: "",
                fft_3: "",
                gst_o: "",
                gst_1: "",
                gst_2: "",
                gst_3: "",
                gst_4: "",
                sst_1: "",
                sst_2: "",
                sst_3: "",
                fct_1: "",
                fct_2: "",
                fct_3: "",
                fct_4: "",
                fct_5: "",
            };

            if(!fileData.print_exp_id || !fileData.phd_id || !fileData.lab_id || !fileData.date /* || !fileData.bioink_id */){
                setSeverity("error");
                setNotification("Mandatory fields in the file are missing.");
                setFileData({});
                setLoading(true);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
                return;
            }
            
            const printbaseExistsRes = await axiosClient.get("printbase/checkPrintbase", {params: {print_exp_id: fileData.print_exp_id}})
                .then((response) => {
                    if (response.data.status === "exists") {
                        setSeverity("error");
                        setNotification("Experiment with same ID already exists.");
                        setFileData({});
                        setLoading(true);
                        sleep(3000).then(()=>{
                            window.location.reload(true);
                        });
                        return;
                    }
                })
                .catch((error) => {
                    setSeverity("error");
                    setNotification("Something went wrong.");
                    setFileData({});
                    setLoading(true);
                    sleep(3000).then(()=>{
                        window.location.reload(true);
                    });
                    return;
                });
            
            if (isNaN(fileData.temp_p_1) || isNaN(fileData.temp_p_2) || isNaN(fileData.temp_p_2)
            || isNaN(fileData.pressure_p_1) || isNaN(fileData.pressure_p_2) || isNaN(fileData.pressure_p_3)
            || isNaN(fileData.mass_1) || isNaN(fileData.mass_2) || isNaN(fileData.mass_3)) {
                setSeverity("error");
                setNotification("Temperature, Pressure and Mass Flow should be numbers.");
                setFileData({});
                setLoading(true);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
                return;
            }

            setFileData(fileData);
            setPrintbase(fileData);
            setReviewVisibility("visible");
            const newFile=new File([file], fileData.print_exp_id+"."+file.name.split('.').pop()); 
            setDataFile([newFile]);
            fileData.csv_file = newFile.name;
        }
    };

    const handleDataDelete = (deletedFile) => {
        window.location.reload(true);
    };
    
    /*----------------------------------------------------------------------------------------------*/

    const [ fft1, setFFT1 ] = useState([]);
    const [ fft2, setFFT2 ] = useState([]);
    const [ fft3, setFFT3 ] = useState([]);
    const [ gsto, setGSTo ] = useState([]);
    const [ gst1, setGST1 ] = useState([]);
    const [ gst2, setGST2 ] = useState([]);
    const [ gst3, setGST3 ] = useState([]);
    const [ gst4, setGST4 ] = useState([]);
    const [ sst1, setSST1 ] = useState([]);
    const [ sst2, setSST2 ] = useState([]);
    const [ sst3, setSST3 ] = useState([]);
    const [ fct1, setFCT1 ] = useState([]);
    const [ fct2, setFCT2 ] = useState([]);
    const [ fct3, setFCT3 ] = useState([]);
    const [ fct4, setFCT4 ] = useState([]);
    const [ fct5, setFCT5 ] = useState([]);

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                
                const printbaseInput = new FormData();
                Object.keys(fileData).forEach(k => {
                    printbaseInput.append(k, fileData[k]);
                });
                if(dataFile[0]) {printbaseInput.append("images", dataFile[0]);}
                if(fft1[0]) {printbaseInput.append("images", fft1[0]);}
                if(fft2[0]) {printbaseInput.append("images", fft2[0]);}
                if(fft3[0]) {printbaseInput.append("images", fft3[0]);}
                if(gsto[0]) {printbaseInput.append("images", gsto[0]);}
                if(gst1[0]) {printbaseInput.append("images", gst1[0]);}
                if(gst2[0]) {printbaseInput.append("images", gst2[0]);}
                if(gst3[0]) {printbaseInput.append("images", gst3[0]);}
                if(gst4[0]) {printbaseInput.append("images", gst4[0]);}
                if(sst1[0]) {printbaseInput.append("images", sst1[0]);}
                if(sst2[0]) {printbaseInput.append("images", sst2[0]);}
                if(sst3[0]) {printbaseInput.append("images", sst3[0]);}
                if(fct1[0]) {printbaseInput.append("images", fct1[0]);}
                if(fct2[0]) {printbaseInput.append("images", fct2[0]);}
                if(fct3[0]) {printbaseInput.append("images", fct3[0]);}
                if(fct4[0]) {printbaseInput.append("images", fct4[0]);}
                if(fct5[0]) {printbaseInput.append("images", fct5[0]);}
                if(bioink) {printbaseInput.append("bioink_id", bioink);}
                const printbaseInputRes = await axiosClient.post("printbase/createPrintbase", printbaseInput);
                setLoading(false);
                setSeverity("success");
                setNotification(printbaseInputRes.data.responseMessage);
                localStorage.setItem("print_exp_id", fileData.print_exp_id);
                sleep(3000).then(()=>{
                    history.push("/printbaseExperiment");
                });
            } catch (err) {
                setSubmitDisabled(false);
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        }
    };

    

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (<>   
                    <LoadingBackdrop open={loading} />
                    <Container component="main" maxWidth="md">
                        <h1>Printbase Data Input</h1>
                        <div>
                        <DropzoneArea
                            acceptedFiles={["application/vnd.ms-excel", ".csv"]}
                            showFileNamesInPreview={true}
                            filesLimit={1}
                            onChange={handleDataChange}
                            onDelete={handleDataDelete}
                            useChipsForPreview={true}
                            dropzoneText="Click to upload or Drag a .csv file here"
                            showAlerts={false}
                        />
                        </div>
                        <Box visibility={reviewVisibility}>
                            <hr></hr>
                            <Typography variant="h5" className={classes.title}>
                                DATA FROM YOUR FILE:
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table" >
                                    <TableBody>
                                        <StyledTableRow key="print_exp_id">
                                            <StyledTableCell component="th" scope="row">
                                                Print Experiment ID:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.print_exp_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="phd_id">
                                            <StyledTableCell component="th" scope="row">
                                                Name:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.phd_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="lab_id">
                                            <StyledTableCell component="th" scope="row">
                                                Lab:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.lab_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="date">
                                            <StyledTableCell component="th" scope="row">
                                                Date:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.date : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink ID:
                                            </StyledTableCell>
                                            <StyledTableCell >
                                            <FormControl variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth 
                                                className={classes.formControl}>
                                                <InputLabel id="category_label">Bioink ID</InputLabel>
                                                <Select
                                                    labelId="Bioink ID"
                                                    required
                                                    fullWidth
                                                    id="bioink"
                                                    value={bioink}
                                                    onChange={(e) => {setBioink(e.target.value)}}
                                                    >
                                                    {
                                                        bioinks.map(ink => (
                                                            <MenuItem key={ink.ink_id} value={ink.ink_id}>{ink.ink_id}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="compos_text">
                                            <StyledTableCell component="th" scope="row">
                                                Composition:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.compos_text : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="print_notes">
                                            <StyledTableCell component="th" scope="row">
                                                Notes:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.print_notes : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="printer_id">
                                            <StyledTableCell component="th" scope="row">
                                                Printer:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.printer_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <Divider />
                                <div></div>
                                <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="right">Experiment 1</StyledTableCell>
                                        <StyledTableCell align="right">Experiment 2</StyledTableCell>
                                        <StyledTableCell align="right">Experiment 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="temperature">
                                            <StyledTableCell component="th" scope="row">
                                                Temperature (°C):
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.temp_p_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.temp_p_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.temp_p_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="pressure">
                                            <StyledTableCell component="th" scope="row">
                                                Pressure (kPa):
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.pressure_p_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.pressure_p_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.pressure_p_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="category">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink Category (A-E):
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.inktype_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.inktype_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.inktype_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="mass_flow">
                                            <StyledTableCell component="th" scope="row">
                                                Mass Flow (g/s):
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.mass_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.mass_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.mass_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="strut_widening">
                                            <StyledTableCell component="th" scope="row">
                                                Strut Widening:
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.sw_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.sw_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.sw_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="fct_category">
                                            <StyledTableCell component="th" scope="row">
                                                FCT Category (A-E):
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.filam_1 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.filam_2 : "----"}</StyledTableCell>
                                            <StyledTableCell align="right">{printbase ? printbase.filam_3 : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box>
                                <hr></hr>
                                <Typography variant="h5" className={classes.title}>
                                    IMAGE UPLOAD:
                                </Typography>
                                <div className={classes.root}>
                                <Grid container spacing={1} md>
                                    <h4>Filament Fusion Test</h4>
                                    <Grid container item md={12} spacing={3}>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT1."+newImages[0].name.split('.').pop()); setFFT1([newFile]); fileData.fft_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT1([]); fileData.fft_1="";}}
                                                previewGridProps={gridProps}
                                            />
                                            </Grid>
                                            <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT2."+newImages[0].name.split('.').pop()); setFFT2([newFile]); fileData.fft_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT2([]); fileData.fft_2="";}}  
                                                previewGridProps={gridProps}                                             
                                            />
                                            </Grid>
                                            <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT3."+newImages[0].name.split('.').pop()); setFFT3([newFile]); fileData.fft_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT3([]); fileData.fft_3="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <h4>Grid Structure Test</h4>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={"GST Overview"}
                                        filesLimit={1}
                                        onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GSTo."+newImages[0].name.split('.').pop()); setGSTo([newFile]); fileData.gst_o=newFile.name;}}}
                                        onDelete={(deletedImage) => {setGSTo([]); fileData.gst_o="";}}
                                        previewGridProps={gridProps}
                                    />
                                    <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 1"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST1."+newImages[0].name.split('.').pop()); setGST1([newFile]); fileData.gst_1=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST1([]); fileData.gst_1="";}}
                                            previewGridProps={gridProps}
                                        />
                                        <h4>DCR_1= {fileData.dcr_1}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 2"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST2."+newImages[0].name.split('.').pop()); setGST2([newFile]); fileData.gst_2=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST2([]); fileData.gst_2="";}}
                                            previewGridProps={gridProps}
                                        />
                                        <h4>DCR_2= {fileData.dcr_2}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 3"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST3."+newImages[0].name.split('.').pop()); setGST3([newFile]); fileData.gst_3=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST3([]); fileData.gst_3="";}}
                                            previewGridProps={gridProps}
                                        />
                                        <h4>DCR_3= {fileData.dcr_3}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 4"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST4."+newImages[0].name.split('.').pop()); setGST4([newFile]); fileData.gst_4=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST4([]); fileData.gst_4="";}}
                                            previewGridProps={gridProps}
                                        />
                                        <h4>DCR_4= {fileData.dcr_4}</h4>
                                    </Grid>
                                    <h4>Strut Spreading Test</h4>
                                    <Grid container item md={12} spacing={3}>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST1."+newImages[0].name.split('.').pop()); setSST1([newFile]); fileData.sst_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST1([]); fileData.sst_1="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST2."+newImages[0].name.split('.').pop()); setSST2([newFile]); fileData.sst_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST2([]); fileData.sst_2="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST3."+newImages[0].name.split('.').pop()); setSST3([newFile]); fileData.sst_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST3([]); fileData.sst_3="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <h4>Filament Collapse Test</h4>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={3}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT1."+newImages[0].name.split('.').pop()); setFCT1([newFile]); fileData.fct_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT1([]); fileData.fct_1="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT2."+newImages[0].name.split('.').pop()); setFCT2([newFile]); fileData.fct_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT2([]); fileData.fct_2="";}}  
                                                previewGridProps={gridProps}                                             
                                            />
                                        </Grid>
                                            <Grid item xs={3}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT3."+newImages[0].name.split('.').pop()); setFCT3([newFile]); fileData.fct_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT3([]); fileData.fct_3="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 4"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT4."+newImages[0].name.split('.').pop()); setFCT4([newFile]); fileData.fct_4=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT4([]); fileData.fct_4="";}}  
                                                previewGridProps={gridProps}                                             
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 5"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT5."+newImages[0].name.split('.').pop()); setFCT5([newFile]); fileData.fct_5=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT5([]); fileData.fct_5="";}}
                                                previewGridProps={gridProps}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </div>
                                
                            </Box>
                            <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={submitDisabled}
                            >
                                Save to Database
                            </Button>
                            </form>                        
                        </Box>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>

                </>)
            }
        </>
    )
}
