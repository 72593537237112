import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useHistory } from 'react-router-dom';
import UserContext from "../../context/UserContext";
import axios from "axios";

export default function AuthOptions() {
    const { userData, setUserData } = useContext(UserContext);
    
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const login = (e) => {
        history.push("/login");
    }

    const changePassword = (e) => {
        history.push("/changePassword");
    }
    
    const logout = async () => {
        try {
            await axiosClient.post("users/logout");
            
        } catch (error) {
           console.log(error.message);
        }
        setUserData({
            token: undefined,
            user: undefined,
            firstname: undefined,
            role: undefined
        });
        localStorage.setItem("auth-token", "");
        history.push("/login")
    };
    
    return (
        <nav>
            {
                userData.user ? (<>
                <Button variant="contained" color="default" onClick={changePassword} startIcon={<LockIcon />}>Change Password</Button>
                <Button variant="contained" color="default" onClick={logout} startIcon={<ExitToAppIcon />}>Logout</Button>
                </>) : (
            <>
            <Button variant="contained" color="secondary" onClick={login} startIcon={<LockOpenIcon />}>Login</Button>
            </>
                )}
        </nav>
    );
}