import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import UserContext from "../../context/UserContext";

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Container from '@material-ui/core/Container';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { parse } from "papaparse";

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#005896",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    
  }));

  const gridProps = {
    container: {
        
    },
    item: {
        xs: 8,
    }
  };

export default function PrintbaseEdit() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
        });

    const [ printbase, setPrintbase ] = useState();
    const [ printExpId, setPrintExpId ] = useState();
    const [ ready, setReady ] = useState(false);
    const [ bioink, setBioink ] = useState();
    const [ bioinks, setBioinks ] = useState([]);

    const [ dataFileurl, setDataFileurl ] = useState("");
    const [ dataFile, setDataFile ] = useState([]);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();
    const [ reviewVisibility, setReviewVisibility ] = useState("hidden");

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ fileData, setFileData ] = useState({
        userId: userData.user,
        print_exp_id: undefined,
        phd_id: undefined,
        lab_id: undefined,
        date: undefined,
        bioink_id: undefined,
        compos_text: undefined,
        print_notes: undefined,
        printer_id: undefined,
        nozzle: undefined,
        substrate: undefined,
        temp_p_1: undefined,
        temp_p_2: undefined,
        temp_p_3: undefined,
        pressure_p_1: undefined,
        pressure_p_2: undefined,
        pressure_p_3: undefined,
        inktype_1: undefined,
        inktype_2: undefined,
        inktype_3: undefined,
        mass_1: undefined,
        mass_2: undefined,
        mass_3: undefined,
        dcr_1: undefined,
        dcr_2: undefined,
        dcr_3: undefined,
        dcr_4: undefined,
        sw_1: undefined,
        sw_2: undefined,
        sw_3: undefined,
        filam_1: undefined,
        filam_2: undefined,
        filam_3: undefined,
        csv_file: "",
        fft_1: "",
        fft_2: "",
        fft_3: "",
        gst_o: "",
        gst_1: "",
        gst_2: "",
        gst_3: "",
        gst_4: "",
        sst_1: "",
        sst_2: "",
        sst_3: "",
        fct_1: "",
        fct_2: "",
        fct_3: "",
        fct_4: "",
        fct_5: "",
    });

    const validated = () => {
        if(!fileData.print_exp_id || !fileData.phd_id || !fileData.lab_id || !fileData.date || !fileData.bioink_id){
            setSeverity("error");
            setNotification("Please fill in all mandatory fields.");
            return false;
        }

        if (isNaN(fileData.temp_p_1) || isNaN(fileData.temp_p_2) || isNaN(fileData.temp_p_2)
            || isNaN(fileData.pressure_p_1) || isNaN(fileData.pressure_p_2) || isNaN(fileData.pressure_p_3)
            || isNaN(fileData.mass_1) || isNaN(fileData.mass_2) || isNaN(fileData.mass_3)) {
                setSeverity("error");
                setNotification("Temperature, Pressure and Mass Flow should be numbers.");
                return false;
            }
        return true;
    }
    
    
    /*----------------------------------------------------------------------------------------------*/

    const [ fft1, setFFT1 ] = useState([]);
    const [ fft2, setFFT2 ] = useState([]);
    const [ fft3, setFFT3 ] = useState([]);
    const [ gsto, setGSTo ] = useState([]);
    const [ gst1, setGST1 ] = useState([]);
    const [ gst2, setGST2 ] = useState([]);
    const [ gst3, setGST3 ] = useState([]);
    const [ gst4, setGST4 ] = useState([]);
    const [ sst1, setSST1 ] = useState([]);
    const [ sst2, setSST2 ] = useState([]);
    const [ sst3, setSST3 ] = useState([]);
    const [ fct1, setFCT1 ] = useState([]);
    const [ fct2, setFCT2 ] = useState([]);
    const [ fct3, setFCT3 ] = useState([]);
    const [ fct4, setFCT4 ] = useState([]);
    const [ fct5, setFCT5 ] = useState([]);

    const [ fft1url, setFFT1url ] = useState();
    const [ fft2url, setFFT2url ] = useState();
    const [ fft3url, setFFT3url ] = useState();
    const [ gstourl, setGSTourl ] = useState();
    const [ gst1url, setGST1url ] = useState();
    const [ gst2url, setGST2url ] = useState();
    const [ gst3url, setGST3url ] = useState();
    const [ gst4url, setGST4url ] = useState();
    const [ sst1url, setSST1url ] = useState();
    const [ sst2url, setSST2url ] = useState();
    const [ sst3url, setSST3url ] = useState();
    const [ fct1url, setFCT1url ] = useState();
    const [ fct2url, setFCT2url ] = useState();
    const [ fct3url, setFCT3url ] = useState();
    const [ fct4url, setFCT4url ] = useState();
    const [ fct5url, setFCT5url ] = useState();

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                
                const printbaseInput = new FormData();
                Object.keys(fileData).forEach(k => {
                    if (k !=='bioink_id') {
                        printbaseInput.append(k, fileData[k]);
                    }
                });
                if(dataFile[0]) {printbaseInput.append("images", dataFile[0]);}
                if(fft1[0]) {printbaseInput.append("images", fft1[0]);}
                if(fft2[0]) {printbaseInput.append("images", fft2[0]);}
                if(fft3[0]) {printbaseInput.append("images", fft3[0]);}
                if(gsto[0]) {printbaseInput.append("images", gsto[0]);}
                if(gst1[0]) {printbaseInput.append("images", gst1[0]);}
                if(gst2[0]) {printbaseInput.append("images", gst2[0]);}
                if(gst3[0]) {printbaseInput.append("images", gst3[0]);}
                if(gst4[0]) {printbaseInput.append("images", gst4[0]);}
                if(sst1[0]) {printbaseInput.append("images", sst1[0]);}
                if(sst2[0]) {printbaseInput.append("images", sst2[0]);}
                if(sst3[0]) {printbaseInput.append("images", sst3[0]);}
                if(fct1[0]) {printbaseInput.append("images", fct1[0]);}
                if(fct2[0]) {printbaseInput.append("images", fct2[0]);}
                if(fct3[0]) {printbaseInput.append("images", fct3[0]);}
                if(fct4[0]) {printbaseInput.append("images", fct4[0]);}
                if(fct5[0]) {printbaseInput.append("images", fct5[0]);}
                if(bioink) {printbaseInput.append("bioink_id", bioink);}
                const printbaseInputRes = await axiosClient.put("printbase/updatePrintbase", printbaseInput);
                setLoading(false);
                setSeverity("success");
                setNotification(printbaseInputRes.data.responseMessage);
                localStorage.setItem("print_exp_id", printExpId);
                sleep(3000).then(()=>{
                    history.push("/printbaseExperiment");
                });
            } catch (err) {
                setLoading(false);
                setSeverity("error");
                setNotification("Something went wrong");
            }
        }
    };


    const initInputs = (printbase) => {
        setDataFileurl(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.csv_file);
        setFFT1url(printbase.fft_1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fft_1);
        setFFT2url(printbase.fft_2==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fft_2);
        setFFT3url(printbase.fft_3==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fft_3);
        setGSTourl(printbase.gst_o==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.gst_o);
        setGST1url(printbase.gst_1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.gst_1);
        setGST2url(printbase.gst_2==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.gst_2);
        setGST3url(printbase.gst_3==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.gst_3);
        setGST4url(printbase.gst_4==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.gst_4);
        setSST1url(printbase.sst_1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.sst_1);
        setSST2url(printbase.sst_2==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.sst_2);
        setSST3url(printbase.sst_3==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.sst_3);
        setFCT1url(printbase.fct_1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fct_1);
        setFCT2url(printbase.fct_2==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fct_2);
        setFCT3url(printbase.fct_3==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fct_3);
        setFCT4url(printbase.fct_4==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fct_4);
        setFCT5url(printbase.fct_5==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printbase.print_exp_id+"--"+printbase.fct_5);

        fileData.fft_1 = printbase.fft_1;
        fileData.fft_2 = printbase.fft_2;
        fileData.fft_3 = printbase.fft_3;
        fileData.gst_o = printbase.gst_o;
        fileData.gst_1 = printbase.gst_1;
        fileData.gst_2 = printbase.gst_2;
        fileData.gst_3 = printbase.gst_3;
        fileData.gst_4 = printbase.gst_4;
        fileData.sst_1 = printbase.sst_1;
        fileData.sst_2 = printbase.sst_2;
        fileData.sst_3 = printbase.sst_3;
        fileData.fct_1 = printbase.fct_1;
        fileData.fct_1 = printbase.fct_1;
        fileData.fct_1 = printbase.fct_1;
        fileData.fct_1 = printbase.fct_1;
        fileData.fct_1 = printbase.fct_1;
    };
    
    useEffect(() => { 
        const loadPrintbase = async () => {  
            if (!printbase && localStorage.getItem("edit_print_exp_id")) {
                try {
                    setPrintExpId(localStorage.getItem("edit_print_exp_id"));
                    const printbaseRes = await axiosClient.get("/printbase/getPrintbase", {params: {print_exp_id: localStorage.getItem("edit_print_exp_id")}});
                    setPrintbase(printbaseRes.data);
                    setFileData(printbaseRes.data);
                    setBioink(printbaseRes.data.bioink_id);
                    initInputs(printbaseRes.data);
                    setReady(true);
                } catch (error) {
                    
                }
            }
        };
        loadAllInks();
        loadPrintbase();
      }, []);

      const loadAllInks = async () => {
        try {
            setLoading(true);
            const inkbasesRes = await axiosClient.get("inkbase/getAll");
            setBioinks(inkbasesRes.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    }

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (ready ?
                    (<Container component="main" maxWidth="md">
                        <LoadingBackdrop open={loading} />
                        <h1>Printbase Edit</h1>
                        {/* <div>
                        <DropzoneArea
                            acceptedFiles={["application/vnd.ms-excel", ".csv"]}
                            showFileNamesInPreview={true}
                            filesLimit={1}
                            onChange={handleDataChange}
                            onDelete={handleDataDelete}
                            useChipsForPreview={true}
                            dropzoneText="Click to upload or Drag a .csv file here"
                            showAlerts={false}
                            initialFiles={[dataFileurl]}
                        />
                        </div> */}
                        <Box visibility="visible">
                            <Typography variant="h5" className={classes.title}>
                                DATA:
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table" >
                                    <TableBody>
                                        <StyledTableRow key="print_exp_id">
                                            <StyledTableCell component="th" scope="row">
                                                Print Experiment ID*:
                                            </StyledTableCell>
                                            <StyledTableCell >{printbase ? printbase.print_exp_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="phd_id">
                                            <StyledTableCell component="th" scope="row">
                                                Name*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="phd_id" defaultValue={printbase.phd_id} onChange={(e) => fileData.phd_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="lab_id">
                                            <StyledTableCell component="th" scope="row">
                                                Lab*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="lab_id" defaultValue={printbase.lab_id} onChange={(e) => fileData.lab_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="date">
                                            <StyledTableCell component="th" scope="row">
                                                Date*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="date" defaultValue={printbase.date} onChange={(e) => fileData.date=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink ID*:
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                <FormControl variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth 
                                                    className={classes.formControl}>
                                                    <InputLabel id="category_label">Bioink ID</InputLabel>
                                                    <Select
                                                        labelId="Bioink ID"
                                                        required
                                                        fullWidth
                                                        id="bioink"
                                                        value={bioink}
                                                        onChange={(e) => {setBioink(e.target.value)}}
                                                        >
                                                        {
                                                            bioinks.map(ink => (
                                                                <MenuItem key={ink.ink_id} value={ink.ink_id}>{ink.ink_id}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="compos_text">
                                            <StyledTableCell component="th" scope="row">
                                                Composition:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField id="compos_text" defaultValue={printbase.compos_text} onChange={(e) => printbase.compos_text=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="print_notes">
                                            <StyledTableCell component="th" scope="row">
                                                Notes:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField multiline id="print_notes" defaultValue={printbase.print_notes} onChange={(e) => printbase.print_notes=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="printer_id">
                                            <StyledTableCell component="th" scope="row">
                                                Printer:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField id="printer_id" defaultValue={printbase.printer_id} onChange={(e) => printbase.printer_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="printer_id">
                                            <StyledTableCell component="th" scope="row">
                                                Nozzle:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField id="nozzle" defaultValue={printbase.nozzle} onChange={(e) => printbase.nozzle=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="substrate">
                                            <StyledTableCell component="th" scope="row">
                                                Printer:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField id="substrate" defaultValue={printbase.substrate} onChange={(e) => printbase.substrate=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <Divider />
                                <div></div>
                                <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Experiment 1</StyledTableCell>
                                        <StyledTableCell>Experiment 2</StyledTableCell>
                                        <StyledTableCell>Experiment 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="temperature">
                                            <StyledTableCell component="th" scope="row">
                                                Temperature (°C):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="temp_p_1" defaultValue={printbase.temp_p_1} onChange={(e) => printbase.temp_p_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="temp_p_2" defaultValue={printbase.temp_p_2} onChange={(e) => printbase.temp_p_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="temp_p_3" defaultValue={printbase.temp_p_3} onChange={(e) => printbase.temp_p_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="pressure">
                                            <StyledTableCell component="th" scope="row">
                                                Pressure (kPa):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="pressure_p_1" defaultValue={printbase.pressure_p_1} onChange={(e) => printbase.pressure_p_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="pressure_p_2" defaultValue={printbase.pressure_p_2} onChange={(e) => printbase.pressure_p_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="pressure_p_3" defaultValue={printbase.pressure_p_3} onChange={(e) => printbase.pressure_p_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="category">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink Category (A-E):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="inktype_1" defaultValue={printbase.inktype_1} onChange={(e) => printbase.inktype_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="inktype_2" defaultValue={printbase.inktype_2} onChange={(e) => printbase.inktype_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="inktype_3" defaultValue={printbase.inktype_3} onChange={(e) => printbase.inktype_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="mass_flow">
                                            <StyledTableCell component="th" scope="row">
                                                Mass Flow (g/s):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="mass_1" defaultValue={printbase.mass_1} onChange={(e) => printbase.mass_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="mass_2" defaultValue={printbase.mass_2} onChange={(e) => printbase.mass_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="mass_3" defaultValue={printbase.mass_3} onChange={(e) => printbase.mass_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="strut_widening">
                                            <StyledTableCell component="th" scope="row">
                                                Strut Widening (SW):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="sw_1" defaultValue={printbase.sw_1} onChange={(e) => printbase.sw_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="sw_2" defaultValue={printbase.sw_2} onChange={(e) => printbase.sw_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="sw_3" defaultValue={printbase.sw_3} onChange={(e) => printbase.sw_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="fct_category">
                                            <StyledTableCell component="th" scope="row">
                                                FCT Category (A-E):
                                            </StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="filam_1" defaultValue={printbase.filam_1} onChange={(e) => printbase.filam_1=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="filam_2" defaultValue={printbase.filam_2} onChange={(e) => printbase.filam_2=e.target.value} /></StyledTableCell>
                                            <StyledTableCell align="right"><TextField number id="filam_3" defaultValue={printbase.filam_3} onChange={(e) => printbase.filam_3=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            <Box>
                                <hr></hr>
                                <Typography variant="h5" className={classes.title}>
                                    IMAGES:
                                </Typography>
                                <div className={classes.root}>
                                <Grid container spacing={1}>
                                    <h4>Filament Fusion Test</h4>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT1."+newImages[0].name.split('.').pop()); setFFT1([newFile]); fileData.fft_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT1([]); fileData.fft_1="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fft1url]}
                                            />
                                            </Grid>
                                            <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT2."+newImages[0].name.split('.').pop()); setFFT2([newFile]); fileData.fft_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT2([]); fileData.fft_2="";}}  
                                                previewGridProps={gridProps}
                                                initialFiles={[fft2url]}                                             
                                            />
                                            </Grid>
                                            <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FFT 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FFT3."+newImages[0].name.split('.').pop()); setFFT3([newFile]); fileData.fft_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFFT3([]); fileData.fft_3="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fft3url]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <h4>Grid Structure Test</h4>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={"GST Overview"}
                                        filesLimit={1}
                                        onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GSTo."+newImages[0].name.split('.').pop()); setGSTo([newFile]); fileData.gst_o=newFile.name;}}}
                                        onDelete={(deletedImage) => {setGSTo([]); fileData.gst_o="";}}
                                        previewGridProps={gridProps}
                                        initialFiles={[gstourl]}
                                    />
                                    <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 1"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST1."+newImages[0].name.split('.').pop()); setGST1([newFile]); fileData.gst_1=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST1([]); fileData.gst_1="";}}
                                            previewGridProps={gridProps}
                                            initialFiles={[gst1url]}
                                        />
                                        <h4>DCR_1= {fileData.dcr_1}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 2"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST2."+newImages[0].name.split('.').pop()); setGST2([newFile]); fileData.gst_2=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST2([]); fileData.gst_2="";}}
                                            previewGridProps={gridProps}
                                            initialFiles={[gst2url]}
                                        />
                                        <h4>DCR_2= {fileData.dcr_2}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 3"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST3."+newImages[0].name.split('.').pop()); setGST3([newFile]); fileData.gst_3=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST3([]); fileData.gst_3="";}}
                                            previewGridProps={gridProps}
                                            initialFiles={[gst3url]}
                                        />
                                        <h4>DCR_3= {fileData.dcr_3}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={"GST 4"}
                                            filesLimit={1}
                                            onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "GST4."+newImages[0].name.split('.').pop()); setGST4([newFile]); fileData.gst_4=newFile.name;}}}
                                            onDelete={(deletedImage) => {setGST4([]); fileData.gst_4="";}}
                                            previewGridProps={gridProps}
                                            initialFiles={[gst4url]}
                                        />
                                        <h4>DCR_4= {fileData.dcr_4}</h4>
                                    </Grid>
                                    <h4>Strut Spreading Test</h4>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST1."+newImages[0].name.split('.').pop()); setSST1([newFile]); fileData.sst_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST1([]); fileData.sst_1="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[sst1url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST2."+newImages[0].name.split('.').pop()); setSST2([newFile]); fileData.sst_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST2([]); fileData.sst_2="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[sst2url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"SST 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "SST3."+newImages[0].name.split('.').pop()); setSST3([newFile]); fileData.sst_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setSST3([]); fileData.sst_3="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[sst3url]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <h4>Filament Collapse Test</h4>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 1"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT1."+newImages[0].name.split('.').pop()); setFCT1([newFile]); fileData.fct_1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT1([]); fileData.fct_1="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fct1url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 2"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT2."+newImages[0].name.split('.').pop()); setFCT2([newFile]); fileData.fct_2=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT2([]); fileData.fct_2="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fct2url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 3"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT3."+newImages[0].name.split('.').pop()); setFCT3([newFile]); fileData.fct_3=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT3([]); fileData.fct_3="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fct3url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 4"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT4."+newImages[0].name.split('.').pop()); setFCT4([newFile]); fileData.fct_4=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT4([]); fileData.fct_4="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fct4url]}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"FCT 5"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "FCT5."+newImages[0].name.split('.').pop()); setFCT5([newFile]); fileData.fct_5=newFile.name;}}}
                                                onDelete={(deletedImage) => {setFCT5([]); fileData.fct_5="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[fct5url]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </div>
                                
                            </Box>
                            <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={submitDisabled}
                            >
                                Save to Database
                            </Button>
                            </form>                        
                        </Box>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>) : (<div>Loading...</div>)
                )
            }
        </>
    )
}
