import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import UserContext from "../../context/UserContext";
import axios from "axios";
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Button } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#005896",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function PrintbaseExperiment(props) {
  const classes = useStyles();
  const { userData } = useContext(UserContext);

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
    });

  const [ printbase, setPrintbase ] = useState();
  const [ printExpId, setPrintExpId ] = useState();
  const [ loading, setLoading ] = useState(true);
  const [ uploadedBy, setUploadedBy ] = useState();

  useEffect(() => { 
    const loadPrintbase = async () => {  
        if (!printbase && localStorage.getItem(props.printExpId)) {
            try {
                setPrintExpId(localStorage.getItem(props.printExpId));
                const printbaseRes = await axiosClient.get("/printbase/getPrintbase", {params: {print_exp_id: localStorage.getItem(props.printExpId)}});
                setPrintbase(printbaseRes.data);
                const uploadedByRes = await axiosClient.get("/users/getUserUploaded", {params: {userId: printbaseRes.data.userId}});
                setUploadedBy(uploadedByRes.data);
                setLoading(false);
            } catch (error) {
                
            }
        }
    };
    window.scroll(0,0);
    loadPrintbase();
  }, []);

  const downloadCsv = async () => {
    window.open(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.csv_file);
  };

  const srlOptions = {
    settings : {
        downloadedFileName : printExpId,
    },
  }

  return (printbase ? (
    <>      <LoadingBackdrop open={loading} />
            <Grid item md={12}>
            {uploadedBy ? (<h4>Uploaded by: {uploadedBy.firstname} {uploadedBy.lastname}</h4>) : (<></>)}
            <Button variant="contained" color="primary" onClick={(e) => downloadCsv(e)} fullWidth={true} >Download CSV File</Button>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="print_exp_id">
                        <StyledTableCell component="th" scope="row">
                            Print Experiment ID:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.print_exp_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="phd_id">
                        <StyledTableCell component="th" scope="row">
                            Name:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.phd_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="lab_id">
                        <StyledTableCell component="th" scope="row">
                            Lab:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.lab_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="date">
                        <StyledTableCell component="th" scope="row">
                            Date:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.date : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioink_id">
                        <StyledTableCell component="th" scope="row">
                            Bioink ID:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.bioink_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="compos_text">
                        <StyledTableCell component="th" scope="row">
                            Composition:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.compos_text : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="print_notes">
                        <StyledTableCell component="th" scope="row">
                            Notes:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.print_notes : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="printer_id">
                        <StyledTableCell component="th" scope="row">
                            Printer:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.printer_id : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="printer_id">
                        <StyledTableCell component="th" scope="row">
                            Nozzle:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.nozzle : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="printer_id">
                        <StyledTableCell component="th" scope="row">
                            Substrate:
                        </StyledTableCell>
                        <StyledTableCell >{printbase ? printbase.substrate : "----"}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <Divider />
            <div></div>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right">Experiment 1</StyledTableCell>
                    <StyledTableCell align="right">Experiment 2</StyledTableCell>
                    <StyledTableCell align="right">Experiment 3</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow key="temperature">
                        <StyledTableCell component="th" scope="row">
                            Temperature (°C):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.temp_p_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.temp_p_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.temp_p_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="pressure">
                        <StyledTableCell component="th" scope="row">
                            Pressure (kPa):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.pressure_p_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.pressure_p_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.pressure_p_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="category">
                        <StyledTableCell component="th" scope="row">
                            Bioink Category (A-E):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.inktype_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.inktype_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.inktype_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="mass_flow">
                        <StyledTableCell component="th" scope="row">
                            Mass Flow (g/s):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.mass_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.mass_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.mass_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="strut_widening">
                        <StyledTableCell component="th" scope="row">
                            Strut Widening (SW):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.sw_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.sw_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.sw_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="fct_category">
                        <StyledTableCell component="th" scope="row">
                            FCT Category (A-E):
                        </StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.filam_1 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.filam_2 : "----"}</StyledTableCell>
                        <StyledTableCell align="right">{printbase ? printbase.filam_3 : "----"}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="mass_average">
                        <StyledTableCell component="th" scope="row">
                            Mass Average: {printbase ? printbase.mass_average : "----"}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="mass_stdv">
                        <StyledTableCell component="th" scope="row">
                            Mass STDV: {printbase ? printbase.mass_stdv : "----"}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="sw_average">
                        <StyledTableCell component="th" scope="row">
                            SW Average: {printbase ? printbase.sw_average : "----"}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="sw_stdv">
                        <StyledTableCell component="th" scope="row">
                            SW STDV: {printbase ? printbase.sw_stdv : "----"}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <SimpleReactLightbox>
            <SRLWrapper options={srlOptions}>
                <div>
                    <h3>Filament Fusion Test</h3>
                    <a href={printbase.fft_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_1 : "images/no_image.jpg"}>
                        <img src={printbase.fft_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_1 : "/images/no_image.jpg"} 
                        alt="FFT1" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fft_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_2 : "images/no_image.jpg"}>
                        <img src={printbase.fft_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_2 : "images/no_image.jpg"} 
                        alt="FFT2" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fft_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_3 : "images/no_image.jpg"}>
                        <img src={printbase.fft_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fft_3 : "images/no_image.jpg"} 
                        alt="FFT3" width="200" height="auto" style={{padding: 8}} />
                    </a>
                </div>
                <div>
                    <h3>Grid Structure Test - Overview</h3>
                    <a href={printbase.gst_o!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_o : "images/no_image.jpg"}>
                        <img src={printbase.gst_o!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_o : "images/no_image.jpg"} 
                        alt="GSTo" width="300" height="auto" style={{padding: 8}} />
                    </a>
                </div>
                <div>
                    <h3>Grid Structure Test - Detailed</h3>
                    <table>
                        <tr>
                            <td>
                                <a href={printbase.gst_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_1 : "images/no_image.jpg"}>
                                    <img src={printbase.gst_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_1 : "images/no_image.jpg"} 
                                    alt="GST1" width="200" height="auto" style={{padding: 8}} />
                                </a>
                            </td>
                            <td>
                                <a href={printbase.gst_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_2 : "images/no_image.jpg"}>
                                    <img src={printbase.gst_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_2 : "images/no_image.jpg"} 
                                    alt="GST2" width="200" height="auto" style={{padding: 8}} />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td align="center">DCR1 = {printbase ? printbase.dcr_1 : "----"}</td>
                            <td align="center">DCR2 = {printbase ? printbase.dcr_2 : "----"}</td>
                        </tr>
                        <tr>
                            <td>
                                <a href={printbase.gst_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_3 : "images/no_image.jpg"}>
                                    <img src={printbase.gst_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_3 : "images/no_image.jpg"} 
                                    alt="GST3" width="200" height="auto" style={{padding: 8}} />
                                </a>
                            </td>
                            <td>
                                <a href={printbase.gst_4!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_4 : "images/no_image.jpg"}>
                                    <img src={printbase.gst_4!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.gst_4 : "images/no_image.jpg"} 
                                    alt="GST4" width="200" height="auto" style={{padding: 8}} />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td align="center">DCR3 = {printbase ? printbase.dcr_3 : "----"}</td>
                            <td align="center">DCR4 = {printbase ? printbase.dcr_4 : "----"}</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h3>Strut Spreading Test</h3>
                    <a href={printbase.sst_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_1 : "images/no_image.jpg"}>
                        <img src={printbase.sst_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_1 : "images/no_image.jpg"} 
                        alt="SST1" width="300" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.sst_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_2 : "images/no_image.jpg"}>
                        <img src={printbase.sst_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_2 : "images/no_image.jpg"} 
                        alt="SST2" width="300" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.sst_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_3 : "images/no_image.jpg"}>
                        <img src={printbase.sst_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.sst_3 : "images/no_image.jpg"} 
                        alt="SST3" width="300" height="auto" style={{padding: 8}} />
                    </a>
                </div>
                <div>
                    <h3>Filament Collapse Test</h3>
                    <a href={printbase.fct_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_1 : "images/no_image.jpg"}>
                        <img src={printbase.fct_1!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_1 : "/images/no_image.jpg"} 
                        alt="FCT1" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fct_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_2 : "images/no_image.jpg"}>
                        <img src={printbase.fct_2!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_2 : "images/no_image.jpg"} 
                        alt="FCT2" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fct_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_3 : "images/no_image.jpg"}>
                        <img src={printbase.fct_3!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_3 : "images/no_image.jpg"} 
                        alt="FCT3" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fct_4!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_4 : "images/no_image.jpg"}>
                        <img src={printbase.fct_4!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_4 : "images/no_image.jpg"} 
                        alt="FCT4" width="200" height="auto" style={{padding: 8}} />
                    </a>
                    <a href={printbase.fct_5!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_5 : "images/no_image.jpg"}>
                        <img src={printbase.fct_5!=="" ? process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/printbase/"+printExpId+"--"+printbase.fct_5 : "images/no_image.jpg"} 
                        alt="FCT5" width="200" height="auto" style={{padding: 8}} />
                    </a>
                </div>
            </SRLWrapper>
            </SimpleReactLightbox>
            </Grid>
    </>) : (<></>)
  );
}
