import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import UserContext from "../../context/UserContext";

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Container from '@material-ui/core/Container';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#70ad45",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    
  }));

  const gridProps = {
    container: {
        
    },
    item: {
        xs: 8,
    }
  };

export default function BiobaseEdit() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
        });

    const [ biobase, setBiobase ] = useState();
    const [ bioExpId, setBioExpId ] = useState();
    const [ ready, setReady ] = useState(false);

    const [ dataFileurl, setDataFileurl ] = useState("");
    const [ dataFile, setDataFile ] = useState([]);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ fileData, setFileData ] = useState({
        userId: userData.user,
        bio_exp_id: undefined,
        phd_id: undefined,
        lab_id: undefined,
        date: undefined,
        bioink_id: undefined,
        compos_text: undefined,
        bio_notes: undefined,
        printer_id: undefined,
        vital_d1_value: undefined,
        vital_d1_stdv: undefined,
        vital_d7_value: undefined,
        vital_d7_stdv: undefined,
        img_vital_d1: "",
        img_vital_d7: "",
        prolif_d1_value: undefined,
        prolif_d1_stdv: undefined,
        prolif_d7_value: undefined,
        prolif_d7_stdv: undefined,
        img_prolif_d1: "",
        img_prolif_d7: "",
        human_cell_type: undefined,
        nih_area_d1_aver: undefined,
        nih_area_d1_stdv: undefined,
        nih_area_d7_aver: undefined,
        nih_area_d7_stdv: undefined,
        nih_peri_d1_aver: undefined,
        nih_peri_d1_stdv: undefined,
        nih_peri_d7_aver: undefined,
        nih_peri_d7_stdv: undefined,
        nih_majax_d1_aver: undefined,
        nih_majax_d1_stdv: undefined,
        nih_majax_d7_aver: undefined,
        nih_majax_d7_stdv: undefined,
        nih_angle_d1_aver: undefined,
        nih_angle_d1_stdv: undefined,
        nih_angle_d7_aver: undefined,
        nih_angle_d7_stdv: undefined,
        nih_circul_d1_aver: undefined,
        nih_circul_d1_stdv: undefined,
        nih_circul_d7_aver: undefined,
        nih_circul_d7_stdv: undefined,
        nih_aspect_d1_aver: undefined,
        nih_aspect_d1_stdv: undefined,
        nih_aspect_d7_aver: undefined,
        nih_aspect_d7_stdv: undefined,
        hum_area_d1_aver: undefined,
        hum_area_d1_stdv: undefined,
        hum_area_d7_aver: undefined,
        hum_area_d7_stdv: undefined,
        hum_peri_d1_aver: undefined,
        hum_peri_d1_stdv: undefined,
        hum_peri_d7_aver: undefined,
        hum_peri_d7_stdv: undefined,
        hum_majax_d1_aver: undefined,
        hum_majax_d1_stdv: undefined,
        hum_majax_d7_aver: undefined,
        hum_majax_d7_stdv: undefined,
        hum_angle_d1_aver: undefined,
        hum_angle_d1_stdv: undefined,
        hum_angle_d7_aver: undefined,
        hum_angle_d7_stdv: undefined,
        hum_circul_d1_aver: undefined,
        hum_circul_d1_stdv: undefined,
        hum_circul_d7_aver: undefined,
        hum_circul_d7_stdv: undefined,
        hum_aspect_d1_aver: undefined,
        hum_aspect_d1_stdv: undefined,
        hum_aspect_d7_aver: undefined,
        hum_aspect_d7_stdv: undefined,
        img_morph_nih_d1: "",
        img_morph_nih_d7: "",
        img_morph_hum_d1: "",
        img_morph_hum_d7: "",
        csv_file: ""
    });

    const validated = () => {
        if(!fileData.bio_exp_id || !fileData.phd_id || !fileData.lab_id || !fileData.date || !fileData.bioink_id){
            setSeverity("error");
            setNotification("Please fill in all mandatory fields.");
            return false;
        }

        if (isNaN(fileData.vital_d1_value) || isNaN(fileData.vital_d1_stdv) || isNaN(fileData.vital_d7_value) || isNaN(fileData.vital_d7_stdv)
            || isNaN(fileData.prolif_d1_value) || isNaN(fileData.prolif_d1_stdv) || isNaN(fileData.prolif_d7_value) || isNaN(fileData.prolif_d7_stdv)
            || isNaN(fileData.nih_area_d1_aver) || isNaN(fileData.nih_area_d1_stdv) || isNaN(fileData.nih_area_d7_aver) || isNaN(fileData.nih_area_d7_stdv)
            || isNaN(fileData.nih_peri_d1_aver) || isNaN(fileData.nih_peri_d1_stdv) || isNaN(fileData.nih_peri_d7_aver) || isNaN(fileData.nih_peri_d7_stdv)
            || isNaN(fileData.nih_majax_d1_aver) || isNaN(fileData.nih_majax_d1_stdv) || isNaN(fileData.nih_majax_d7_aver) || isNaN(fileData.nih_majax_d7_stdv)
            || isNaN(fileData.nih_angle_d1_aver) || isNaN(fileData.nih_angle_d1_stdv) || isNaN(fileData.nih_angle_d7_aver) || isNaN(fileData.nih_angle_d7_stdv)
            || isNaN(fileData.nih_circul_d1_aver) || isNaN(fileData.nih_circul_d1_stdv) || isNaN(fileData.nih_circul_d7_aver) || isNaN(fileData.nih_circul_d7_stdv)
            || isNaN(fileData.nih_aspect_d1_aver) || isNaN(fileData.nih_aspect_d1_stdv) || isNaN(fileData.nih_aspect_d7_aver) || isNaN(fileData.nih_aspect_d7_stdv)
            /* || isNaN(fileData.hum_area_d1_aver) || isNaN(fileData.hum_area_d1_stdv) || isNaN(fileData.hum_area_d7_aver) || isNaN(fileData.hum_area_d7_stdv)
            || isNaN(fileData.hum_peri_d1_aver) || isNaN(fileData.hum_peri_d1_stdv) || isNaN(fileData.hum_peri_d7_aver) || isNaN(fileData.hum_peri_d7_stdv)
            || isNaN(fileData.hum_majax_d1_aver) || isNaN(fileData.hum_majax_d1_stdv) || isNaN(fileData.hum_majax_d7_aver) || isNaN(fileData.hum_majax_d7_stdv)
            || isNaN(fileData.hum_angle_d1_aver) || isNaN(fileData.hum_angle_d1_stdv) || isNaN(fileData.hum_angle_d7_aver) || isNaN(fileData.hum_angle_d7_stdv)
            || isNaN(fileData.hum_circul_d1_aver) || isNaN(fileData.hum_circul_d1_stdv) || isNaN(fileData.hum_circul_d7_aver) || isNaN(fileData.hum_circul_d7_stdv)
            || isNaN(fileData.hum_aspect_d1_aver) || isNaN(fileData.hum_aspect_d1_stdv) || isNaN(fileData.hum_aspect_d7_aver) || isNaN(fileData.hum_aspect_d7_stdv) */) {
                setSeverity("error");
                setNotification("Please check the numerical values.");
                return false;
            }
        return true;
    }
    
    
    /*----------------------------------------------------------------------------------------------*/

    const [ imgVitalD1, setImgVitalD1 ] = useState([]);
    const [ imgVitalD7, setImgVitalD7 ] = useState([]);
    const [ imgProlifD1, setImgProlifD1 ] = useState([]);
    const [ imgProlifD7, setImgProlifD7 ] = useState([]);
    
    const [ imgMorphNihD1, setImgMorphNihD1 ] = useState([]);
    const [ imgMorphNihD7, setImgMorphNihD7 ] = useState([]);
    const [ imgMorphHumD1, setImgMorphHumD1 ] = useState([]);
    const [ imgMorphHumD7, setImgMorphHumD7 ] = useState([]);

    const [ imgVitalD1url, setImgVitalD1url ] = useState([]);
    const [ imgVitalD7url, setImgVitalD7url ] = useState([]);
    const [ imgProlifD1url, setImgProlifD1url ] = useState([]);
    const [ imgProlifD7url, setImgProlifD7url ] = useState([]);
    
    const [ imgMorphNihD1url, setImgMorphNihD1url ] = useState([]);
    const [ imgMorphNihD7url, setImgMorphNihD7url ] = useState([]);
    const [ imgMorphHumD1url, setImgMorphHumD1url ] = useState([]);
    const [ imgMorphHumD7url, setImgMorphHumD7url ] = useState([]);

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                
                const biobaseInput = new FormData();
                Object.keys(fileData).forEach(k => {
                    biobaseInput.append(k, fileData[k]);
                });
                if(dataFile[0]) {biobaseInput.append("images", dataFile[0]);}
                if(imgVitalD1[0]) {biobaseInput.append("images", imgVitalD1[0]);}
                if(imgVitalD7[0]) {biobaseInput.append("images", imgVitalD7[0]);}
                if(imgProlifD1[0]) {biobaseInput.append("images", imgProlifD1[0]);}
                if(imgProlifD7[0]) {biobaseInput.append("images", imgProlifD7[0]);}
                if(imgMorphNihD1[0]) {biobaseInput.append("images", imgMorphNihD1[0]);}
                if(imgMorphNihD7[0]) {biobaseInput.append("images", imgMorphNihD7[0]);}
                /* if(imgMorphHumD1[0]) {biobaseInput.append("images", imgMorphHumD1[0]);}
                if(imgMorphHumD7[0]) {biobaseInput.append("images", imgMorphHumD7[0]);} */
                const biobaseInputRes = await axiosClient.put("biobase/updateBiobase", biobaseInput);
                setLoading(false);
                setSeverity("success");
                setNotification(biobaseInputRes.data.responseMessage);
                localStorage.setItem("bio_exp_id", bioExpId);
                sleep(3000).then(()=>{
                    history.push("/biobaseExperiment");
                });
            } catch (err) {
                setLoading(false);
                setSeverity("error");
                setNotification("Something went wrong");
            }
        }
    };


    const initInputs = (biobase) => {
        setDataFileurl(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.csv_file);
        setImgVitalD1url(biobase.img_vital_d1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_vital_d1);
        setImgVitalD7url(biobase.img_vital_d7==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_vital_d7);
        setImgProlifD1url(biobase.img_prolif_d1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_prolif_d1);
        setImgProlifD7url(biobase.img_prolif_d7==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_prolif_d7);
        setImgMorphNihD1url(biobase.img_morph_nih_d1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_morph_nih_d1);
        setImgMorphNihD7url(biobase.img_morph_nih_d7==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_morph_nih_d7);
        setImgMorphHumD1url(biobase.img_morph_hum_d1==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_morph_hum_d1);
        setImgMorphHumD7url(biobase.img_morph_hum_d7==="" ? null : process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/biobase/"+biobase.bio_exp_id+"--"+biobase.img_morph_hum_d7);

        fileData.img_vital_d1 = biobase.img_vital_d1;
        fileData.img_vital_d7 = biobase.img_vital_d7;
        fileData.img_prolif_d1 = biobase.img_prolif_d1;
        fileData.img_prolif_d7 = biobase.img_prolif_d7;
        fileData.img_morph_nih_d1 = biobase.img_morph_nih_d1;
        fileData.img_morph_nih_d7 = biobase.img_morph_nih_d7;
        fileData.img_morph_hum_d1 = biobase.img_morph_hum_d1;
        fileData.img_morph_hum_d7 = biobase.img_morph_hum_d7;
    };
    
    useEffect(() => { 
        const loadBiobase = async () => {  
            if (!biobase && localStorage.getItem("edit_bio_exp_id")) {
                try {
                    setBioExpId(localStorage.getItem("edit_bio_exp_id"));
                    const biobaseRes = await axiosClient.get("/biobase/getBiobase", {params: {bio_exp_id: localStorage.getItem("edit_bio_exp_id")}});
                    setBiobase(biobaseRes.data);
                    setFileData(biobaseRes.data);
                    initInputs(biobaseRes.data);
                    setReady(true);
                } catch (error) {
                    
                }
            }
        };
        loadBiobase();
      }, []);

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (false ?
                    (<Container component="main" maxWidth="md">
                        <LoadingBackdrop open={loading} />
                        <h1>Biobase Edit</h1>
                        {/* <div>
                        <DropzoneArea
                            acceptedFiles={["application/vnd.ms-excel", ".csv"]}
                            showFileNamesInPreview={true}
                            filesLimit={1}
                            onChange={handleDataChange}
                            onDelete={handleDataDelete}
                            useChipsForPreview={true}
                            dropzoneText="Click to upload or Drag a .csv file here"
                            showAlerts={false}
                            initialFiles={[dataFileurl]}
                        />
                        </div> */}
                        <Box visibility="visible">
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table" >
                                    <TableBody>
                                        <StyledTableRow key="bio_exp_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bio Experiment ID*:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.bio_exp_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="phd_id">
                                            <StyledTableCell component="th" scope="row">
                                                Name*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="phd_id" defaultValue={biobase.phd_id} onChange={(e) => fileData.phd_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="lab_id">
                                            <StyledTableCell component="th" scope="row">
                                                Lab*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="lab_id" defaultValue={biobase.lab_id} onChange={(e) => fileData.lab_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="date">
                                            <StyledTableCell component="th" scope="row">
                                                Date*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="date" defaultValue={biobase.date} onChange={(e) => fileData.date=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink ID*:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField required id="bioink_id" defaultValue={biobase.bioink_id} onChange={(e) => fileData.bioink_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bio_notes">
                                            <StyledTableCell component="th" scope="row">
                                                Notes:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField multiline id="bio_notes" defaultValue={biobase.bio_notes} onChange={(e) => biobase.bio_notes=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="printer_id">
                                            <StyledTableCell component="th" scope="row">
                                                Printer:
                                            </StyledTableCell>
                                            <StyledTableCell ><TextField id="printer_id" defaultValue={biobase.printer_id} onChange={(e) => biobase.printer_id=e.target.value} /></StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <Divider />
                                <div></div>
                                <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 1</StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 7</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="vitality">
                                            <StyledTableCell component="th" scope="row">
                                                Vitality
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"Vitality Day 1 - Image"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_D1."+newImages[0].name.split('.').pop()); setImgVitalD1([newFile]); fileData.img_vital_d1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setImgVitalD1([]); fileData.img_vital_d1="";}}
                                                previewGridProps={gridProps}
                                                initialFiles={[imgVitalD1url]}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Vitality Day 7 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_D7."+newImages[0].name.split('.').pop()); setImgVitalD7([newFile]); fileData.img_vital_d7=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgVitalD7([]); fileData.img_vital_d7="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgVitalD7url]}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="vitality_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.vital_d1_value} onChange={(e) => biobase.vital_d1_value=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.vital_d1_stdv} onChange={(e) => biobase.vital_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.vital_d7_value} onChange={(e) => biobase.vital_d7_value=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.vital_d7_stdv} onChange={(e) => biobase.vital_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 1</StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 7</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="proliferation">
                                            <StyledTableCell component="th" scope="row">
                                            Proliferation
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_D1."+newImages[0].name.split('.').pop()); setImgProlifD1([newFile]); fileData.img_prolif_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifD1([]); fileData.img_prolif_d1="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgProlifD1url]}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 7 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_D7."+newImages[0].name.split('.').pop()); setImgProlifD7([newFile]); fileData.img_prolif_d7=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifD7([]); fileData.img_prolif_d7="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgProlifD7url]}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="proliferation_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.prolif_d1_value} onChange={(e) => biobase.prolif_d1_value=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.prolif_d1_stdv} onChange={(e) => biobase.prolif_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.prolif_d7_value} onChange={(e) => biobase.prolif_d7_value=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.prolif_d7_stdv} onChange={(e) => biobase.prolif_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 1</StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 7</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_nih">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_NIH_D1."+newImages[0].name.split('.').pop()); setImgMorphNihD1([newFile]); fileData.img_morph_nih_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphNihD1([]); fileData.img_morph_nih_d1="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgMorphNihD1url]}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 7 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_NIH_D7."+newImages[0].name.split('.').pop()); setImgMorphNihD7([newFile]); fileData.img_morph_nih_d7=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphNihD7([]); fileData.img_morph_nih_d7="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgMorphNihD7url]}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_area">
                                            <StyledTableCell component="th" scope="row">
                                                Area (µm²)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_area_d1_aver} onChange={(e) => biobase.nih_area_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_area_d1_stdv} onChange={(e) => biobase.nih_area_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_area_d7_aver} onChange={(e) => biobase.nih_area_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_area_d7_stdv} onChange={(e) => biobase.nih_area_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_perimeter">
                                            <StyledTableCell component="th" scope="row">
                                                Perimeter (µm)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_peri_d1_aver} onChange={(e) => biobase.nih_peri_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_peri_d1_stdv} onChange={(e) => biobase.nih_peri_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_peri_d7_aver} onChange={(e) => biobase.nih_peri_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_peri_d7_stdv} onChange={(e) => biobase.nih_peri_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_majax">
                                            <StyledTableCell component="th" scope="row">
                                                Major Axis (µm)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_majax_d1_aver} onChange={(e) => biobase.nih_majax_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_majax_d1_stdv} onChange={(e) => biobase.nih_majax_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_majax_d7_aver} onChange={(e) => biobase.nih_majax_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_majax_d7_stdv} onChange={(e) => biobase.nih_majax_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_angle">
                                            <StyledTableCell component="th" scope="row">
                                                Angle (°)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_angle_d1_aver} onChange={(e) => biobase.nih_angle_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_angle_d1_stdv} onChange={(e) => biobase.nih_angle_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_angle_d7_aver} onChange={(e) => biobase.nih_angle_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_angle_d7_stdv} onChange={(e) => biobase.nih_angle_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_circul">
                                            <StyledTableCell component="th" scope="row">
                                                Circularity
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_circul_d1_aver} onChange={(e) => biobase.nih_circul_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_circul_d1_stdv} onChange={(e) => biobase.nih_circul_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_circul_d7_aver} onChange={(e) => biobase.nih_circul_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_circul_d7_stdv} onChange={(e) => biobase.nih_circul_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_nih_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_aspect_d1_aver} onChange={(e) => biobase.nih_aspect_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_aspect_d1_stdv} onChange={(e) => biobase.nih_aspect_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.nih_aspect_d7_aver} onChange={(e) => biobase.nih_aspect_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.nih_aspect_d7_stdv} onChange={(e) => biobase.nih_aspect_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    {/* <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 1</StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>Day 7</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_hum">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology <TextField number id="human_cell_type" defaultValue={biobase.human_cell_type} onChange={(e) => biobase.human_cell_type=e.target.value} /> Reporter Cells
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={biobase ? "Morphology "+biobase.human_cell_type+" Day 1 - Image" : "Morphology Human Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Hum_D1."+newImages[0].name.split('.').pop()); setImgMorphHumD1([newFile]); fileData.img_morph_hum_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphHumD1([]); fileData.img_morph_hum_d1="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgMorphHumD1url]}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right" colSpan={3}>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={biobase ? "Morphology "+biobase.human_cell_type+" Day 7 - Image" : "Morphology Human Day 7 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Hum_D7."+newImages[0].name.split('.').pop()); setImgMorphHumD7([newFile]); fileData.img_morph_hum_d7=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphHumD7([]); fileData.img_morph_hum_d7="";}}
                                                    previewGridProps={gridProps}
                                                    initialFiles={[imgMorphHumD7url]}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_area">
                                            <StyledTableCell component="th" scope="row">
                                                Area (µm²)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_area_d1_aver} onChange={(e) => biobase.hum_area_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_area_d1_stdv} onChange={(e) => biobase.hum_area_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_area_d7_aver} onChange={(e) => biobase.hum_area_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_area_d7_stdv} onChange={(e) => biobase.hum_area_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_perimeter">
                                            <StyledTableCell component="th" scope="row">
                                                Perimeter (µm)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_peri_d1_aver} onChange={(e) => biobase.hum_peri_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_peri_d1_stdv} onChange={(e) => biobase.hum_peri_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_peri_d7_aver} onChange={(e) => biobase.hum_peri_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_peri_d7_stdv} onChange={(e) => biobase.hum_peri_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_majax">
                                            <StyledTableCell component="th" scope="row">
                                                Major Axis (µm)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_majax_d1_aver} onChange={(e) => biobase.hum_majax_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_majax_d1_stdv} onChange={(e) => biobase.hum_majax_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_majax_d7_aver} onChange={(e) => biobase.hum_majax_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_majax_d7_stdv} onChange={(e) => biobase.hum_majax_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_angle">
                                            <StyledTableCell component="th" scope="row">
                                                Angle (°)
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_angle_d1_aver} onChange={(e) => biobase.hum_angle_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_angle_d1_stdv} onChange={(e) => biobase.hum_angle_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_angle_d7_aver} onChange={(e) => biobase.hum_angle_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_angle_d7_stdv} onChange={(e) => biobase.hum_angle_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_circul">
                                            <StyledTableCell component="th" scope="row">
                                                Circularity
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_circul_d1_aver} onChange={(e) => biobase.hum_circul_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_circul_d1_stdv} onChange={(e) => biobase.hum_circul_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_circul_d7_aver} onChange={(e) => biobase.hum_circul_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_circul_d7_stdv} onChange={(e) => biobase.hum_circul_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_aspect_d1_aver} onChange={(e) => biobase.hum_aspect_d1_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_aspect_d1_stdv} onChange={(e) => biobase.hum_aspect_d1_stdv=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_value" defaultValue={biobase.hum_aspect_d7_aver} onChange={(e) => biobase.hum_aspect_d7_aver=e.target.value} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                 ± 
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextField number id="vital_d1_stdv" defaultValue={biobase.hum_aspect_d7_stdv} onChange={(e) => biobase.hum_aspect_d7_stdv=e.target.value} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody> */}
                                </Table>
                            </TableContainer>
                            <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                disabled={submitDisabled}
                            >
                                Save to Database
                            </Button>
                            </form>                        
                        </Box>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>) : (<div>This section is under construction. Thank you for your understanding.</div>)
                )
            }
        </>
    )
}
