import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserContext from "../../context/UserContext";
import axios from 'axios';
import _ from 'underscore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import EditIcon from '@material-ui/icons/Edit';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
    
  }));

  const sortByInputs = [
    {
        value: 'bio_exp_id',
        label: 'Experiment ID'
    },
    {
        value: 'phd_id',
        label: 'PhD ID'
    },
    {
        value: 'lab_id',
        label: 'Lab ID'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'bioink_id',
        label: 'BioInk ID'
    },
];

export default function BiobaseUpdatePage() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    let compareRef = useRef();

    const [ allBiobases, setAllBiobases ] = useState([]);
    const [ biobases, setBiobases ] = useState([]);
    const [ biobasesLoaded, setBiobasesLoaded ] = useState(false);
    let searchKey = "";
    const [ sortBy, setSortBy ] = useState("");

    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
    const [ deleteDialogTitle, setDeleteDialogTitle ] = useState("Delete");
    const [ deleteBiobase, setDeleteBiobase ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        const loadMyBiobases = async () => {
            try {
                setLoading(true);
                const biobasesRes = await axiosClient.get("biobase/getMyBiobases");
                setAllBiobases(biobasesRes.data);
                setBiobases(biobasesRes.data);
                setBiobasesLoaded(true);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        };
        const loadAllBiobases = async () => {
            try {
                setLoading(true);
                const biobasesRes = await axiosClient.get("biobase/getAll");
                setAllBiobases(biobasesRes.data);
                setBiobases(biobasesRes.data);
                setBiobasesLoaded(true);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        };
        if (userData.role === "admin") {
            loadAllBiobases();
        } else {
            loadMyBiobases();
        }

    }, []);

    
    const handleListItemClick = (e, bioExpId) => {
        localStorage.setItem("bio_exp_id", bioExpId);
        history.push("/biobaseExperiment");
    };

    const handleEditClick = (e, pId) => {
        localStorage.setItem("edit_bio_exp_id", pId);
        history.push("/biobaseEdit");
    };

    const handleDeleteClick = (e, biobase) => {
        setDeleteDialogTitle("Delete experiment with ID: " + biobase.bio_exp_id + " ?")
        setOpenDeleteDialog(true);
        setDeleteBiobase(biobase);
    };

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    };

    const deleteBiobaseConfirm = async (e) => {
        try {
            handleCloseDeleteDialog();
            const biobaseDeleteRes = await axiosClient.delete("biobase/deleteBiobase", {params: {bio_exp_id_id: deleteBiobase.id}});
            setSeverity("success");
            setNotification(biobaseDeleteRes.data.responseMessage);
            sleep(3000).then(()=>{
                history.push("/dashboard");
            });
        } catch (error) {
            setSeverity("error");
            setNotification("Something went wrong.");
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    
    const filterBiobases = () => {
        const results = [];
        allBiobases.forEach(biobase => {
            if (biobase.bio_exp_id.includes(searchKey) || biobase.phd_id.includes(searchKey) || biobase.lab_id.includes(searchKey)
            || biobase.date.includes(searchKey) || biobase.bioink_id.includes(searchKey)) {
                results.push(biobase);
            }
        });
        setBiobases(results);
    };

    const sortBiobases = (sortKey) => {
        setBiobases(_.sortBy(biobases, sortKey));
    };

    return (
        <>
            {
            (!userData.token) ?
            (<div>You are not authorised to access this section. Please login.</div>) :
            (<div>
                <LoadingBackdrop open={loading} />
                <Container component="main" maxWidth="md">
                    <h1>Biobase Data Update</h1>
                </Container>
                <Container maxWidth="md">
                {(biobasesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="biobase_search"
                                    label="Search Biobase"
                                    name="biobase_search"
                                    onChange={(e) => {searchKey=e.target.value; filterBiobases();}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="biobase_sort"
                                    name="biobase_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortBiobases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(biobases.length < 1) ? (<h5>No results</h5>) : (<div></div>)}

                <List>
                    {biobases.map(biobase => (
                        <ListItem button onClick={(e) => handleListItemClick(e, biobase.bio_exp_id)} key={biobase.bio_exp_id}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={biobase.bio_exp_id}
                                secondary={`Name: ${biobase.phd_id} || Lab: ${biobase.lab_id} || Date: ${biobase.date} || BioinkID: ${biobase.bioink_id}`}
                            />
                            <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleEditClick(e, biobase.bio_exp_id)}>
                                    Edit
                                </Button>
                                <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, biobase)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                </Container>
                <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{deleteDialogTitle}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The experiment data will be permanently deleted. Are you sure?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={deleteBiobaseConfirm} color="secondary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="secondary" autoFocus>
                        No
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>)
            }
            
            {notification && (
                <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
            )}
        </>
    );
}
