import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CardActionArea } from '@material-ui/core';

import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import Footer from '../layouts/Footer';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundImage: `url(${"/images/background.jpg"})`,
      marginTop: theme.spacing(1),
    },
    card: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
      height: 0
    },
    cardContent: {
      flexGrow: 1,
      color: "white"
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
}));

const cards = [
  {
    image: "/images/Internal_Documents.jpeg",
    heading: "Internal Documents",
    description: "",
    backgroundColor: "#005896",
    forward : "/internalDocuments"
  },
  {
    image: "/images/TRR225_databases.jpeg",
    heading: "TRR225 Databases",
    description: "",
    backgroundColor: "#005896",
    forward : "/dashboard"
  },
];

export default function PreDashboard() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();
  
    const forward = (page) => {
      history.push(page);
    }

    return (
      <>
      {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
      (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
            Welcome to BioFab Datastore
            </Typography>
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.heading} xs={12} sm={6} lg={6}>
                <Card className={classes.card} style={{backgroundColor: card.backgroundColor}} onClick={() => forward(card.forward)}>
                  <CardActionArea>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title={card.heading}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                  </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
    )
    }
    </>
  )
}
