import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserContext from "../../context/UserContext";
import BiobaseExperiment from "./BiobaseExperiment";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  cont: {
      display: 'flex',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const BiobaseExperimentCompare = (props) => {
  const classes = useStyles();
  const { userData } = useContext(UserContext);
  return(
      <>
      {
      (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) :
      (<div>
          <Container component="main" maxWidth="xl" >
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <h1>Biobase Comparison</h1>
              </Grid>
              <Grid item xs={6}>
                  <BiobaseExperiment bioExpId="compareBiobase1" />
              </Grid>
              <Grid item xs={6}>
                  <BiobaseExperiment bioExpId="compareBiobase2" />
              </Grid>
          </Grid>
          </Container>
      </div>)
      }
    </>
   )

 }

export default BiobaseExperimentCompare