import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateNewPassword() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 

  const [ newPassword, setNewPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ] = useState("");
  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();
  
  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ resetPasswordTokenValid, setResetPasswordTokenValid ] = useState(false);
  const [ resetPassword, setResetPassword ] = useState();
  const resetPasswordToken = new URLSearchParams(useLocation().search).get('resetPasswordToken');

  useEffect(() => {
    const checkTokenValidity = async () => {
      const resetPasswordTokenValidRes = await axiosClient.post("users/resetPasswordTokenValid", {resetPasswordToken: resetPasswordToken});
      if (!resetPasswordTokenValidRes.data) {
        setResetPasswordTokenValid(false);
      } else {
        setResetPasswordTokenValid(true);
        setResetPassword(resetPasswordTokenValidRes.data);
      }
    };
    setLoading(true);
    checkTokenValidity();
    setLoading(false);
  }, []);

  const validated = () => {
    if (!newPassword || !confirmPassword) {
      setSeverity("error");
      setNotification("Please fill in all fields.");
      return false;
    }

    if (newPassword.length < 8) {
      setSeverity("error");
      setNotification("Passwords should be at least 8 characters.");
      return false;
    }

    if (newPassword !== confirmPassword) {
      setSeverity("error");
      setNotification("New password and Confirm password do not match.");
      return false;
    }

    return true;
  }

  /* const clearFields = () => {
    setNewPassword("");
    setConfirmPassword("");
  } */

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
}

  const submit = async (e) => {
      e.preventDefault();

      if (validated()) {
        setSubmitDisabled(true);
        setLoading(true);
        try{
          const createNewPassword = { resetPasswordToken, newPassword, confirmPassword };
          const createNewPasswordRes = await axiosClient.post("users/resetPassword", createNewPassword);
          setLoading(false);
          setSeverity("success");
          setNotification(createNewPasswordRes.data.responseMessage);
          sleep(3000).then(()=>{
            history.push("/login");
          });
        } catch (err) {
          setSubmitDisabled(false);
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      }      
  };

  return (
    <>
    {
      (!resetPasswordTokenValid) ?
      (<div>Your password reset token has expired. Please use the change password feature if you can log in or else request for password reset 
          by clicking on 'Forgot Password' on the Login page.</div>) : (
      <>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create New Password
        </Typography>
        {(resetPassword) ?
        (<p>Hello {resetPassword.trrFirstname},<br/>
        Forgot your password? No problem. Just create a new password for your BioFab account and you will be good to go.
        </p>) :(<div></div>)
        }
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="newpassword"
                label="New Password"
                type="password"
                id="newpassword"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
          >
            Change Password
          </Button>
        </form>
      </div>
    </Container>
    </>
    )
  }
  </>);
}