import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from "axios";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const [ username, setUsername ] = useState();
  const [ password, setPassword ] = useState();

  const { userData, setUserData } = useContext(UserContext);
  const history = useHistory();
  
  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();
  
  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  
  const [ openForgotPasswordDialog, setOpenForgotPasswordDialog ] = useState(false);
  const [ forgotPasswordDialogTitle, setForgotPasswordDialogTitle ] = useState("ForgotPassword");

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  });
  
  const validation = () => {
    
    if (!username || !password) {
      setSeverity("error");
      setNotification("Please enter both Username and Password.");
      return false;
    }
    if (username.length < 4) {
      setSeverity("error");
      setNotification("Please check the Username.");
      return false;
    }
    if (password.length < 8) {
      setSeverity("error");
      setNotification("Password should be at least 8 characters.");
      return false;
    }
    return true;
  }

  const submit = async (e) => {
      e.preventDefault();
      
      if (validation()) {
        setSubmitDisabled(true);
        setLoading(true);
        const newUser = { username, password };
      
        try{
        const loginRes = await axiosClient.post("users/login", newUser);
        
        if (true) {
          const getUserRes = await axiosClient.get("/users/getUser", { headers: { "x-auth-token": loginRes.data.authToken}});
          setUserData({ 
            token: loginRes.data.authToken,
            user: loginRes.data.userId,
            firstname: getUserRes.data.firstname,
            role: getUserRes.data.role
          });
          localStorage.setItem("auth-token", loginRes.data.authToken);
          setLoading(false);
          history.push("/");
        }
          
        } catch(err) {
          setSubmitDisabled(false);
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      }
  };

  const validationForgotPassword = () => {
    
    if (!username) {
      setSeverity("error");
      setNotification("Please enter Username to reset your password.");
      return false;
    }
    if (username.length < 4) {
      setSeverity("error");
      setNotification("Please check the Username.");
      return false;
    }
    return true;
  };

  const handleForgotPasswordClick = async () => {
    
    if (validationForgotPassword()) {
      const userExistsRes = await axiosClient.post("/users/userExists", {username: username});
      if (!userExistsRes.data) {
        setSeverity("error");
        setNotification("Username does not exist.");
        return;
      }
      setForgotPasswordDialogTitle("Forgot Password?");
      setOpenForgotPasswordDialog(true);
    }
  };

  const handleCloseForgotPasswordDialog = () => {
    setOpenForgotPasswordDialog(false);
  };

  const forgotPasswordConfirm = async (e) => {
    try {
      const forgotPasswordRes = await axiosClient.post("users/forgotPassword", {username: username});
      setSeverity("info");
      setNotification(forgotPasswordRes.data.responseMessage);
      setOpenForgotPasswordDialog(false);
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
      setOpenForgotPasswordDialog(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <LoadingBackdrop open={loading} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeySharpIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login!
        </Typography>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handleForgotPasswordClick}>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        open={openForgotPasswordDialog}
        onClose={handleCloseForgotPasswordDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{forgotPasswordDialogTitle}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will receive a link via Email to create a new password for your BioFab account. Are you sure?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={forgotPasswordConfirm} color="secondary">
            Yes
        </Button>
        <Button onClick={handleCloseForgotPasswordDialog} color="secondary" autoFocus>
            No
        </Button>
        </DialogActions>
    </Dialog>
    </Container>
  );
}