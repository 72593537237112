import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#005896",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperList: {
    width: 300,
    height: 300,
    overflow: 'auto',
  },
}));

export default function ApproveInkbase() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 
  
  const entry = {};
  const [ inkName, setInkName ] = useState();
  const [ category, setCategory ] = useState();
  const [ composition, setComposition ] = useState();
  const [ sop_file, setSop_file ] = useState();

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ inkbaseRequestTokenValid, setInkbaseRequestTokenValid ] = useState(false);
  const [ inkbaseRequest, setInkbaseRequest ] = useState();
  const [ requestedBy, setRequestedBy ] = useState();
  const inkbaseRequestToken = new URLSearchParams(useLocation().search).get('inkbaseRequestToken');
  
  const [ openInfoDialog, setOpenInfoDialog ] = useState(false);

  useEffect(() => {
    const loadInkbase = async () => {
            const inkbaseRequestTokenValidRes = await axiosClient.post("inkbase/inkbaseRequestTokenCheck", {inkbaseRequestToken: inkbaseRequestToken});
            if (!inkbaseRequestTokenValidRes.data) {
                setInkbaseRequestTokenValid(false);
            } else {
                setInkbaseRequestTokenValid(true);
                setInkbaseRequest(inkbaseRequestTokenValidRes.data);
                setInkName(inkbaseRequestTokenValidRes.data.ink_id);
                setCategory(inkbaseRequestTokenValidRes.data.category);
                setComposition(inkbaseRequestTokenValidRes.data.composition);
                setSop_file(inkbaseRequestTokenValidRes.data.sop_file);
                const requestedByRes = await axiosClient.get("users/getUserById", { params: { userIdId: parseInt(inkbaseRequestTokenValidRes.data.userId)}});
                setRequestedBy(requestedByRes.data);
            }
    }
    setLoading(true);
    loadInkbase();
    setLoading(false);
  }, []);

  const validated = () => {
    
  }

  const clearFields = () => {
    setInkName("");
  }

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
  }

  const approveInk = async () => {
    setSubmitDisabled(true);
    setLoading(true);
    try {
        const approveInkbaseRes = await axiosClient.post("inkbase/approveInkbase", 
        { id: inkbaseRequest.id,
          inkName,
          category,
          composition,
        });
        setSeverity("success");
        setNotification(approveInkbaseRes.data.responseMessage);
        sleep(5000).then(()=>{
        setLoading(false);
        history.push("/dashboard");
        });
    } catch (err) {
        setSubmitDisabled(false);
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const discardInk = async () => {
    setSubmitDisabled(true);
    setLoading(true);
    try {
        const approveInkbaseRes = await axiosClient.post("inkbase/discardInkbase", 
        { id: inkbaseRequest.id,
            inkName,
            category,
            composition,
        });
        setSeverity("success");
        setNotification(approveInkbaseRes.data.responseMessage);
        sleep(5000).then(()=>{
        setLoading(false);
        history.push("/dashboard");
        });
    } catch (err) {
        setSubmitDisabled(false);
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const handleInfoClick = (e) => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const downloadSop = async () => {
    window.open(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/inkbase/"+sop_file);
  };

  return (
    <>
    {  (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) : (
      (!inkbaseRequestTokenValid) ?
      (<div>Either a decision regarding the concerned ink has already been made or the token has expired.</div>) : (
      <main /* style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}} */>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}/*  style={{backgroundColor: "#ffffff"}} */>
        <Typography component="h1" variant="h5">
          Approve/Discard the following Ink
        </Typography>
        {/* <IconButton aria-label="delete" onClick={(e) => handleInfoClick()}>
          <InfoIcon fontSize='large'/>
        </IconButton> */}
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="requestedBy">
                        <StyledTableCell component="th" scope="row">
                            Requested By:
                        </StyledTableCell>
                        <StyledTableCell >{requestedBy ? requestedBy.firstname+" "+requestedBy.lastname : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="inkbaseName">
                        <StyledTableCell component="th" scope="row">
                            Ink Name:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="inkname"
                          label="Ink Name"
                          name="inkname"
                          value={inkName}
                          onChange={(e) => setInkName(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="inkbaseName">
                        <StyledTableCell component="th" scope="row">
                            Category:
                        </StyledTableCell>
                        <StyledTableCell >
                          <FormControl variant="outlined"
                            margin="normal"
                            required
                            fullWidth 
                            className={classes.formControl}>
                            <InputLabel id="category_label">Category</InputLabel>
                            <Select
                                labelId="Category"
                                required
                                fullWidth
                                id="category"
                                value={category}
                                onChange={(e) => {setCategory(e.target.value)}}
                                >
                                <MenuItem key={"ADA based"} value={"ADA based"}>ADA based</MenuItem>
                                <MenuItem key={"Alginate based"} value={"Alginate based"}>Alginate based</MenuItem>
                                <MenuItem key={"ECM based"} value={"ECM based"}>ECM based</MenuItem>
                                <MenuItem key={"Gelatine based"} value={"Gelatine based"}>Gelatine based</MenuItem>
                                <MenuItem key={"Hyaluronic acid based"} value={"Hyaluronic acid based"}>Hyaluronic acid based</MenuItem>
                                <MenuItem key={"PEG based"} value={"PEG based"}>PEG based</MenuItem>
                                <MenuItem key={"POx based"} value={"POx based"}>POx based</MenuItem>
                                <MenuItem key={"Silk based"} value={"Silk based"}>Silk based</MenuItem>
                                <MenuItem key={"Others"} value={"Others"}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="inkbaseName">
                        <StyledTableCell component="th" scope="row">
                            Composition:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="composition"
                          label="Composition"
                          name="composition"
                          value={composition}
                          onChange={(e) => setComposition(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="inkbaseName">
                        <StyledTableCell component="th" scope="row">
                            SOP:
                        </StyledTableCell>
                        <StyledTableCell >
                            <Button variant="contained" color="primary" onClick={(e) => downloadSop(e)} fullWidth={true} >Download SOP File</Button>
                        </StyledTableCell>
                    </StyledTableRow>
                    
                </TableBody>
            </Table>
            </TableContainer>
          <Grid container spacing={3}>
          <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={submitDisabled}
            onClick={approveInk}
          >
            Approve
          </Button>
          </Grid>
          <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitDisabled}
            onClick={discardInk}
          >
            Discard
          </Button>
          </Grid>
          </Grid>
          
      </div>
    </Container>
    </main>
    ))
  }
  </>);
}