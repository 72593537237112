import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserContext from "../../context/UserContext";
import PrintbaseExperiment from "./PrintbaseExperiment";

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 700,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

export default function PrintbaseExperimentPage() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);

    return (
        <>
            {
            (!userData.token) ?
            (<div>You are not authorised to access this section. Please login.</div>) :
            (<div>
                <Container component="main" maxWidth="md">
                    <h1>Printbase Experiment</h1>
                    <PrintbaseExperiment printExpId="print_exp_id" />
                </Container>
                </div>)
            }
        </>
    );
}
