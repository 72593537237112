import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _, { methods } from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import LabelIcon from '@material-ui/icons/Label';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'ink_id',
        label: 'Ink Name'
    },
    {
        value: 'category',
        label: 'Category'
    },
    {
        value: 'composition',
        label: 'Composition'
    },
];

export default function InkbaseView() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allInkbases, setAllInkbases ] = useState([]);
    const [ inkbases, setInkbases ] = useState([]);
    const [ inkbasesLoaded, setInkbasesLoaded ] = useState(false);
    /* let searchKey = ""; */
    const [ sortBy, setSortBy ] = useState("");
  
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadAllInkbases();
    }, []);

    const loadAllInkbases = async () => {
        try {
            setLoading(true);
            const inkbasesRes = await axiosClient.get("inkbase/getAll");
            const usersRes = await axiosClient.get("users/getAll");
            inkbasesRes.data.forEach(inkbase => {
                usersRes.data.forEach(user => {
                    if (user.id.toString() === inkbase.userId) {
                        inkbase.firstname = user.firstname;
                        inkbase.lastname = user.lastname;
                        inkbase.email = user.email;
                    }
                })
            });
            setInkbases(inkbasesRes.data);
            setAllInkbases(inkbasesRes.data);
            setInkbasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    };

    const handleEditClick = (e, mId) => {
        localStorage.setItem("edit_inkbase_id", mId);
        history.push("/inkbaseEdit");
    };

    const handleDownloadClick = (e, sop_file) => {
        window.open(process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/inkbase/"+sop_file);
    }

    let compareRef = useRef();

    const filterInkbases = (searchKey) => {
        const results = [];
        allInkbases.forEach(inkbase => {
            if (inkbase.ink_id.toUpperCase().includes(searchKey.toUpperCase()) || inkbase.category.toUpperCase().includes(searchKey.toUpperCase()) ||
            inkbase.composition.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(inkbase);
            }
        });
        setInkbases(results);
    };
    
    const sortInkbases = (sortKey) => {
        setInkbases(_.sortBy(inkbases, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    View All Inks
                    </Typography>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(inkbasesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="inkbases_search"
                                    label="Search Inkbases"
                                    name="inkbases_search"
                                    onChange={(e) => {/* searchKey=e.target.value; */ filterInkbases(e.target.value);}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="inkbases_sort"
                                    name="inkbases_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortInkbases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(inkbases.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {inkbases.map(inkbase => (
                        <ListItem key={inkbase.availableInkbase}>
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={inkbase.ink_id}
                                secondary={<div>Created By: {React.createElement('a', {href:"mailto:"+inkbase.email}, inkbase.firstname + ' ' + inkbase.lastname)} || Category: {inkbase.category} || Composition:  {inkbase.composition} || Date: {inkbase.date}</div>} 
                            />
                            <ListItemSecondaryAction>
                                <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => handleDownloadClick(e, inkbase.sop_file)}>
                                    SOP
                                </Button>
                                {/* <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, entry)}>
                                    <DeleteForeverIcon />
                                </IconButton> */}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
