import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {DropzoneArea} from 'material-ui-dropzone';

import axios from "axios";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { parse } from "papaparse";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      /* margin: theme.spacing(1), */
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
     /*  margin: theme.spacing(3, 0, 2), */
    },
    formControl: {
        width: '100%'
      }
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

  const wuerzburgDevices = [
      {value: "MCR301"},
      {value: "MCR702"}
  ];
  const erlangenDevices = [
      {value: "ARG2"},
      {value: "DHR3"}
  ];
  
  const wuerzburgGeometries = [
      {value: "CP60-0.5"},
      {value: "PP25"},
      {value: "PP50"},
      {value: "PP50/E/TI"}
  ];
  const erlangenGeometries = [
      {value: "KP20"},
      {value: "KP25"},
      {value: "KP40"},
      {value: "KP60"},
      {value: "PP20"},
      {value: "PP25"},
      {value: "PP40"},
      {value: "PP60"}
  ];

  const unsafeCharacters = /[`!@#$%^&*()\+=\[\]{};':"\\|,<>\/?~ ]/;

export default function RheobaseInput() {
    const classes = useStyles();

    const { userData, setUserData } = useContext(UserContext);
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });
    
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ devices, setDevices ] = useState([]);
    const [ geometries, setGeometries ] = useState([]);

    const [ ink_exp_id, setInk_exp_id ] = useState();
    const [ ink_id, setInk_id ] = useState("");
    const [ date, setDate ] = useState(new Date());
    const [ category, setCategory ] = useState("");
    const [ name, setName ] = useState();
    const [ location, setLocation ] = useState("");
    const [ device, setDevice ] = useState("");
    const [ geometry, setGeometry ] = useState("");
    const [ notes, setNotes ] = useState("-");
    const [ csv_file, setCsvFile ] = useState();
    const [ allIntervalRowsFinal, setAllIntervalRowsFinal ] = useState([]);
    const [ intervalDataFinal, setIntervalDataFinal ] = useState([]);
    const [ intervalsRows, setIntervalsRows ] = useState([]);
    
    const [ bioink, setBioink ] = useState();
    const [ bioinks, setBioinks ] = useState([]);

    let intervalNoOfRows = [];
    let intervalData = [];
    let allIntervalRows = [];
    const [ dataFile, setDataFile ] = useState([]);

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ categoryDisabled, setCategoryDisabled ] = useState(false);
    const [ locationDisabled, setLocationDisabled ] = useState(false);

    const [ submitEndpoint, setSubmitEndpoint ] = useState("rheobase/createRheobase");

    useEffect(() => {
        window.scrollTo(0, 0);
        loadAllInks();
    }, []);

    const loadAllInks = async () => {
        try {
            setLoading(true);
            const inkbasesRes = await axiosClient.get("inkbase/getAll");
            setBioinks(inkbasesRes.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    }
    
    const inputFilter = (selectedLocation) => {
        setLocation(selectedLocation);
        if (selectedLocation === "Würzburg") {
            setDevices(wuerzburgDevices);
            setGeometries(wuerzburgGeometries);
            setDevice("");
            setGeometry("");
        }
        else if (selectedLocation ==="Erlangen") {
            setDevices(erlangenDevices);
            setGeometries(erlangenGeometries);
            setDevice("");
            setGeometry("");
        }
        else if (selectedLocation === "Bayreuth") {
            setDevices(wuerzburgDevices);
            setGeometries(wuerzburgGeometries);
            setDevice("");
            setGeometry("");
        }
    };

    const validated = () => {
        if (!ink_exp_id /* || ink_id==="" */ || !name || location==="" || device==="" || geometry==="") {
            setSeverity("error");
            setNotification("Please fill all mandatory fields.");
            return false;
        }
        if (!bioink) {
            setSeverity("error");
            setNotification("Please select an ink.");
            return false;
        }
        return true;
    }

    const handleDataChange = async (newFiles) => {
        const file = newFiles[0];
        try {
        if (file) {
            setCategoryDisabled(true);
            setLocationDisabled(true);
            if (!location) {
                setSeverity("error");
                setNotification("Please select the Location first.");
                setLoading(true);
                sleep(3000).then(() => {
                    window.location.reload(true);
                });
                return;
            }
            /* if (!(location === "Würzburg" || location === "Bayreuth")) {
                setSeverity("error");
                setNotification("At the moment, only uploads from Würzburg and Bayreuth devices are possible.");
                setLoading(true);
                sleep(3000).then(() => {
                    window.location.reload(true);
                });
                return;
            } */
            const text = await file.text();
            const parsed = parse(text);
            if (unsafeCharacters.test(parsed.data[2][1])) {
                setSeverity("error");
                setNotification("Please remove special characters from experiment ID.");
                setLoading(true);
                sleep(3000).then(() => {
                    window.location.reload(true);
                });
                return;
            }
            setInk_exp_id(parsed.data[2][1]);

            if (isNaN(parsed.data[10][2])) {
                setSeverity("error");
                setNotification("Please check the numeric data format in CSV file");
                setLoading(true);
                sleep(3000).then(() => {
                    window.location.reload(true);
                });
                return;
            }

            const rheobaseExistsRes = await axiosClient.get("rheobase/checkRheobase", {params: {ink_exp_id: parsed.data[2][1]}})
                .then((response) => {
                    if (response.data.status === "exists") {
                        setSeverity("error");
                        setNotification("Experiment with same ID already exists.");
                        setLoading(true);
                        sleep(3000).then(() => {
                            window.location.reload(true);
                        });
                        return;
                    }
            })
            .catch((error) => {
                setSeverity("error");
                setNotification("Something went wrong.");
                setLoading(true);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
                return;
            });
            /* if (category === "SOP") {
                let pointer = 0;
                for (let i = 1; i < 9; i++) {
                    let intervalPointer = 6+5*(i-1)+pointer;
                    intervalNoOfRows.push(parseInt(parsed.data[intervalPointer][2]));
                    let intervalN = [];
                    for (let j = 0; j < intervalNoOfRows[i-1]; j++) {
                        let intervalRow = [];
                        intervalRow.push(parsed.data[2][1]);
                        intervalRow.push((i+1).toString());
                        for (let k = 0; k < 15; k++) {
                            if (parsed.data[j+intervalPointer+4][k+1] === '') {
                                intervalRow.push(undefined);
                            } else {
                                intervalRow.push(parseFloat(parsed.data[j+intervalPointer+4][k+1]));
                            }
                        }
                        intervalN.push(intervalRow);
                        allIntervalRows.push(intervalRow);
                    }
                    intervalData.push(intervalN);
                    pointer+=intervalNoOfRows[i-1];
                }
                setSubmitEndpoint("rheobase/createSopRheobase"); */
            if (category === "SOP") {
                let pointer = 0;
                for (let i = 1; i < 10; i++) {
                    let intervalPointer = 6+5*(i-1)+pointer;
                    intervalNoOfRows.push(parseInt(parsed.data[intervalPointer][2]));
                    let intervalN = [];
                    for (let j = 0; j < intervalNoOfRows[i-1]; j++) {
                        let intervalRow = [];
                        intervalRow.push(parsed.data[2][1]); // Experiment ID
                        if (i === 8 || i === 9) {
                            intervalRow.push((i).toString()); // Interval Number
                        } else {
                            intervalRow.push((i+1).toString()); // Interval Number
                        }
                        for (let k = 0; k < 15; k++) {
                            if (parsed.data[j+intervalPointer+4][k+1] === '') {
                                intervalRow.push(undefined);
                            } else {
                                intervalRow.push(parseFloat(parsed.data[j+intervalPointer+4][k+1]));
                            }
                        }
                        intervalN.push(intervalRow);
                        allIntervalRows.push(intervalRow);
                    }
                    intervalData.push(intervalN);
                    if (i === 3 || i === 7) {
                        pointer+=intervalNoOfRows[i-1];
                    } else {
                        pointer+=intervalNoOfRows[i-1]+2;
                    }
                }
                setSubmitEndpoint("rheobase/createSopRheobase");
            } else {
                let pointer = 0;
                for (let i = 0; i < 9; i++) {
                    let intervalPointer = 6+5*i+pointer;
                    intervalNoOfRows.push(parseInt(parsed.data[intervalPointer][2]));
                    let intervalN = [];
                    for (let j = 0; j < intervalNoOfRows[i]; j++) {
                        let intervalRow = [];
                        intervalRow.push(parsed.data[2][1]); // Experiment ID
                        intervalRow.push((i+1).toString()); // Interval Number
                        for (let k = 0; k < 15; k++) {
                            if (parsed.data[j+intervalPointer+4][k+1] === '') {
                                intervalRow.push(undefined);
                            } else {
                                intervalRow.push(parseFloat(parsed.data[j+intervalPointer+4][k+1]));
                            }
                        }
                        intervalN.push(intervalRow);
                        allIntervalRows.push(intervalRow);
                    }
                    intervalData.push(intervalN);
                    pointer+=intervalNoOfRows[i];
                }
                setCategory("Rheobase");
                setSubmitEndpoint("rheobase/createRheobase");
            }
            
            const newFile=new File([file], parsed.data[2][1]+"."+file.name.split('.').pop()); 
            setCsvFile(newFile.name);
            setDataFile([newFile]);
            setIntervalsRows(intervalNoOfRows);
            setIntervalDataFinal(intervalData);
            setAllIntervalRowsFinal(allIntervalRows);
        }
        
        } catch (error) {
            setSeverity("error");
            setNotification("CSV file is invalid.");
            setLoading(true);
            sleep(3000).then(()=>{
                window.location.reload(true);
            });
        }
    };

    const handleDataDelete = (deletedFile) => {
        window.location.reload(true);
    };

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                const rheobaseInput = new FormData();
                rheobaseInput.append("category", category);
                rheobaseInput.append("userId", userData.user);
                rheobaseInput.append("ink_exp_id", ink_exp_id);
                rheobaseInput.append("ink_id", bioink);
                rheobaseInput.append("date", date);
                rheobaseInput.append("name", name);
                rheobaseInput.append("location", location);
                rheobaseInput.append("device", device);
                rheobaseInput.append("geometry", geometry);
                rheobaseInput.append("notes", notes);
                rheobaseInput.append("csv_file", csv_file);
                rheobaseInput.append("allIntervalRowsFinal", JSON.stringify(allIntervalRowsFinal));
                if(dataFile[0]) {rheobaseInput.append("images", dataFile[0]);}
                const rheobaseInputRes = await axiosClient.post(submitEndpoint, rheobaseInput);
                setSeverity("success");
                setNotification(rheobaseInputRes.data.responseMessage);
                sleep(3000).then(()=>{
                    setLoading(false);
                    history.push('/dashboard');
                });
            } catch (err) {
                setSubmitDisabled(false);
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        }
    };

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (
                    <Container component="main" maxWidth="sm">
                    <LoadingBackdrop open={loading} />
                        <h1>Rheobase Data Input</h1>
                            <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            {
                                (userData.role==="admin" || userData.role==="sopuser") ?
                                (
                                    <FormControl variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        className={classes.formControl}>
                                        <InputLabel id="category_label">Category</InputLabel>
                                        <Select
                                            labelId="Category"
                                            required
                                            fullWidth
                                            id="category"
                                            value={category}
                                            disabled={categoryDisabled}
                                            onChange={(e) => {setCategory(e.target.value)}}
                                            >
                                            <MenuItem key={"SOP"} value={"SOP"}>SOP</MenuItem>
                                            <MenuItem key={"Rheobase"} value={"Rheobase"}>Rheobase</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (<div></div>)
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="location_label">Location</InputLabel>
                                <Select
                                    labelId="Location"
                                    required
                                    fullWidth
                                    id="location"
                                    value={location}
                                    disabled={locationDisabled}
                                    onChange={(e) => {inputFilter(e.target.value)}}
                                    >
                                    <MenuItem key={"Würzburg"} value={"Würzburg"}>Würzburg</MenuItem>
                                    <MenuItem key={"Erlangen"} value={"Erlangen"}>Erlangen</MenuItem>
                                    <MenuItem key={"Bayreuth"} value={"Bayreuth"}>Bayreuth</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="device_label">Device</InputLabel>
                                <Select
                                    labelId="Device"
                                    required
                                    fullWidth
                                    id="device"
                                    value={device}
                                    onChange={(e) => setDevice(e.target.value)}
                                    >
                                    {devices.map(device =>
                                        <MenuItem key={device.value} value={device.value}>{device.value}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="geometry_label">Geometry</InputLabel>
                                <Select
                                    labelId="Geometry"
                                    required
                                    fullWidth
                                    id="geometry"
                                    value={geometry}
                                    onChange={(e) => setGeometry(e.target.value)}
                                    >
                                    {geometries.map(geometry =>
                                        <MenuItem key={geometry.value} value={geometry.value}>{geometry.value}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <DropzoneArea
                                acceptedFiles={["application/vnd.ms-excel", ".csv"]}
                                showFileNamesInPreview={true}
                                filesLimit={1}
                                onChange={handleDataChange}
                                onDelete={handleDataDelete}
                                useChipsForPreview={true}
                                dropzoneText="Click to upload or Drag a .csv file here"
                                showAlerts={false}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ink_exp_id"
                                helperText="Ink Experiment ID (automatically read from the CSV file)"
                                name="ink_exp_id"
                                autoFocus
                                disabled
                                value={ink_exp_id}
                                onChange={(e) => setInk_exp_id(e.target.value)}
                            />
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="ink_id_label">Ink ID</InputLabel>
                                <Select
                                    labelId="Ink ID"
                                    required
                                    fullWidth
                                    id="ink_id"
                                    value={bioink}
                                    onChange={(e) => setBioink(e.target.value)}
                                    >
                                    {
                                        bioinks.map(ink => (
                                            <MenuItem key={ink.ink_id} value={ink.ink_id}>{ink.ink_id}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    fullWidth
                                    id="date"
                                    label="Date"
                                    value={date}
                                    onChange={(date) => setDate(date)}
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                id="notes"
                                label="Notes"
                                name="notes"
                                onChange={(e) => setNotes(e.target.value)}
                            />
                            <ColorButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Save to Database
                            </ColorButton>
                            </form>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>

                )
            }
        </>
    )
}
