import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function NotoficationSnackbar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [message] = React.useState(props.message);
  const [severity] = React.useState(props.severity);

  useEffect(() => {
      if (message) {
          handleClick();
      }
  }, [message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    props.clearNotification();
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}