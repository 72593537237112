import React, { useState, useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import UserContext from "../../context/UserContext";
import { parse } from "papaparse";

import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#70ad45",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    preview: {
        display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    }
  }));


  const gridProps = {
    container: {
        
    },
    item: {
        xs: 8,
    }
  };

  const cellTypes = [
    {value: "Human"},
    {value: "Murine"}
  ];
  const morphologyReporters = [
    {value: "tdTomato"},
    {value: "LifeAct"}
  ]


export default function BiobaseInput() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ biobase, setBiobase ] = useState();
    const [ name, setName ] = useState();
    const [ lab, setLab ] = useState();
    const [ bioink, setBioink ] = useState();
    const [ bioinks, setBioinks ] = useState([]);
    const [ cellType, setCellType ] = useState("");
    const [ morphologyReporter, setMorphologyReporter ] = useState("");
    const [ dataFile, setDataFile ] = useState([]);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();
    const [ reviewVisibility, setReviewVisibility ] = useState("hidden");

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    useEffect(() => { 
        const loadLogin = async () => {
            const loginRes = await axiosClient.get("/users/getUser", { params: { "userId": userData.userId}});
            setName(loginRes.data.firstname + " " + loginRes.data.lastname);
            setLab(loginRes.data.department);
        };
        window.scroll(0,0);
        loadLogin();
        loadAllInks();
      }, []);

      const loadAllInks = async () => {
        try {
            setLoading(true);
            const inkbasesRes = await axiosClient.get("inkbase/getAll");
            setBioinks(inkbasesRes.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    }

    const [ fileData, setFileData ] = useState({
        userId: userData.user,
        bio_exp_id: undefined,
        phd_id: undefined,
        lab_id: undefined,
        date: undefined,
        /* bioink_id: undefined, */
        compos_text: undefined,
        bio_notes: undefined,
        printer_id: undefined,
        vital_alg_d1_value: undefined,
        vital_alg_d1_stdv: undefined,
        vital_alg_d3_value: undefined,
        vital_alg_d3_stdv: undefined,
        img_vital_alg_d1: "",
        img_vital_alg_d3: "",
        prolif_alg_d1_value: undefined,
        prolif_alg_d1_stdv: undefined,
        prolif_alg_d3_value: undefined,
        prolif_alg_d3_stdv: undefined,
        img_prolif_alg_d1: "",
        img_prolif_alg_d3: "",
        human_cell_type: undefined,
        round_alg_d1_aver: undefined,
        round_alg_d1_stdv: undefined,
        round_alg_d3_aver: undefined,
        round_alg_d3_stdv: undefined,
        img_morph_alg_d1: "",
        img_morph_alg_d3: "",
        aspect_alg_d1_aver: undefined,
        aspect_alg_d1_stdv: undefined,
        aspect_alg_d3_aver: undefined,
        aspect_alg_d3_stdv: undefined,
        soli_alg_d1_aver: undefined,
        soli_alg_d1_stdv: undefined,
        soli_alg_d3_aver: undefined,
        soli_alg_d3_stdv: undefined,
        vital_gel_d1_value: undefined,
        vital_gel_d1_stdv: undefined,
        vital_gel_d3_value: undefined,
        vital_gel_d3_stdv: undefined,
        img_vital_gel_d1: "",
        img_vital_gel_d3: "",
        prolif_gel_d1_value: undefined,
        prolif_gel_d1_stdv: undefined,
        prolif_gel_d3_value: undefined,
        prolif_gel_d3_stdv: undefined,
        img_prolif_gel_d1: "",
        img_prolif_gel_d3: "",
        round_gel_d1_aver: undefined,
        round_gel_d1_stdv: undefined,
        round_gel_d3_aver: undefined,
        round_gel_d3_stdv: undefined,
        img_morph_gel_d1: "",
        img_morph_gel_d3: "",
        aspect_gel_d1_aver: undefined,
        aspect_gel_d1_stdv: undefined,
        aspect_gel_d3_aver: undefined,
        aspect_gel_d3_stdv: undefined,
        soli_gel_d1_aver: undefined,
        soli_gel_d1_stdv: undefined,
        soli_gel_d3_aver: undefined,
        soli_gel_d3_stdv: undefined,
        vital_psb_d1_value: undefined,
        vital_psb_d1_stdv: undefined,
        vital_psb_d3_value: undefined,
        vital_psb_d3_stdv: undefined,
        img_vital_psb_d1: "",
        img_vital_psb_d3: "",
        prolif_psb_d1_value: undefined,
        prolif_psb_d1_stdv: undefined,
        prolif_psb_d3_value: undefined,
        prolif_psb_d3_stdv: undefined,
        img_prolif_psb_d1: "",
        img_prolif_psb_d3: "",
        round_psb_d1_aver: undefined,
        round_psb_d1_stdv: undefined,
        round_psb_d3_aver: undefined,
        round_psb_d3_stdv: undefined,
        img_morph_psb_d1: "",
        img_morph_psb_d3: "",
        aspect_psb_d1_aver: undefined,
        aspect_psb_d1_stdv: undefined,
        aspect_psb_d3_aver: undefined,
        aspect_psb_d3_stdv: undefined,
        soli_psb_d1_aver: undefined,
        soli_psb_d1_stdv: undefined,
        soli_psb_d3_aver: undefined,
        soli_psb_d3_stdv: undefined,
        /* hum_area_d1_aver: undefined,
        hum_area_d1_stdv: undefined,
        hum_area_d7_aver: undefined,
        hum_area_d7_stdv: undefined,
        hum_peri_d1_aver: undefined,
        hum_peri_d1_stdv: undefined,
        hum_peri_d7_aver: undefined,
        hum_peri_d7_stdv: undefined,
        hum_majax_d1_aver: undefined,
        hum_majax_d1_stdv: undefined,
        hum_majax_d7_aver: undefined,
        hum_majax_d7_stdv: undefined,
        hum_angle_d1_aver: undefined,
        hum_angle_d1_stdv: undefined,
        hum_angle_d7_aver: undefined,
        hum_angle_d7_stdv: undefined,
        hum_circul_d1_aver: undefined,
        hum_circul_d1_stdv: undefined,
        hum_circul_d7_aver: undefined,
        hum_circul_d7_stdv: undefined,
        hum_aspect_d1_aver: undefined,
        hum_aspect_d1_stdv: undefined,
        hum_aspect_d7_aver: undefined,
        hum_aspect_d7_stdv: undefined, */
        /* img_morph_hum_d1: "",
        img_morph_hum_d7: "" */
    });

    const validated = () => {
        if(!fileData.bio_exp_id || !fileData.phd_id || !fileData.lab_id || !fileData.date/*  || !fileData.bioink_id */){
            setSeverity("error");
            setNotification("Please fill in all fields.");
            return false;
        }
        return true;
    }
    
    const handleDataChange = async (newFiles) => {
        const file = newFiles[0];
        if (file) {
            const text = await file.text();
            const parsed = parse(text);
           
            const fileData = {
                userId: userData.user,
                bio_exp_id: parsed.data[3][5],
                phd_id: parsed.data[5][5],
                lab_id: parsed.data[7][5],
                date: parsed.data[9][5],
                /* bioink_id: bioink, */
                cellType: cellType,
                morphologyReporter: morphologyReporter,
                /* compos_text: parsed.data[13][5], */
                bio_notes: parsed.data[11][5],
                printer_id: parsed.data[13][5],
                vital_alg_d1_value: parsed.data[18][5],
                vital_alg_d1_stdv: parsed.data[19][5],
                vital_alg_d3_value: parsed.data[20][5],
                vital_alg_d3_stdv: parsed.data[21][5],
                img_vital_alg_d1: "",
                img_vital_alg_d3: "",
                prolif_alg_d1_value: parsed.data[38][5],
                prolif_alg_d1_stdv: parsed.data[39][5],
                prolif_alg_d3_value: parsed.data[40][5],
                prolif_alg_d3_stdv: parsed.data[41][5],
                img_prolif_alg_d1: "",
                img_prolif_alg_d3: "",
                /* human_cell_type: parsed.data[29][7], */
                round_alg_d1_aver: parsed.data[24][5],
                round_alg_d1_stdv: parsed.data[25][5],
                round_alg_d3_aver: parsed.data[26][5],
                round_alg_d3_stdv: parsed.data[27][5],
                img_morph_alg_d1: "",
                img_morph_alg_d3: "",
                aspect_alg_d1_aver: parsed.data[28][5],
                aspect_alg_d1_stdv: parsed.data[29][5],
                aspect_alg_d3_aver: parsed.data[30][5],
                aspect_alg_d3_stdv: parsed.data[31][5],
                soli_alg_d1_aver: parsed.data[32][5],
                soli_alg_d1_stdv: parsed.data[33][5],
                soli_alg_d3_aver: parsed.data[34][5],
                soli_alg_d3_stdv: parsed.data[35][5],
                vital_gel_d1_value: parsed.data[18][11],
                vital_gel_d1_stdv: parsed.data[19][11],
                vital_gel_d3_value: parsed.data[20][11],
                vital_gel_d3_stdv: parsed.data[21][11],
                img_vital_gel_d1: "",
                img_vital_gel_d3: "",
                prolif_gel_d1_value: parsed.data[38][11],
                prolif_gel_d1_stdv: parsed.data[39][11],
                prolif_gel_d3_value: parsed.data[40][11],
                prolif_gel_d3_stdv: parsed.data[41][11],
                img_prolif_gel_d1: "",
                img_prolif_gel_d3: "",
                round_gel_d1_aver: parsed.data[24][11],
                round_gel_d1_stdv: parsed.data[25][11],
                round_gel_d3_aver: parsed.data[26][11],
                round_gel_d3_stdv: parsed.data[27][11],
                img_morph_gel_d1: "",
                img_morph_gel_d3: "",
                aspect_gel_d1_aver: parsed.data[28][11],
                aspect_gel_d1_stdv: parsed.data[29][11],
                aspect_gel_d3_aver: parsed.data[30][11],
                aspect_gel_d3_stdv: parsed.data[31][11],
                soli_gel_d1_aver: parsed.data[32][11],
                soli_gel_d1_stdv: parsed.data[33][11],
                soli_gel_d3_aver: parsed.data[34][11],
                soli_gel_d3_stdv: parsed.data[35][11],
                vital_psb_d1_value: parsed.data[18][17],
                vital_psb_d1_stdv: parsed.data[19][17],
                vital_psb_d3_value: parsed.data[20][17],
                vital_psb_d3_stdv: parsed.data[21][17],
                img_vital_psb_d1: "",
                img_vital_psb_d3: "",
                prolif_psb_d1_value: parsed.data[38][17],
                prolif_psb_d1_stdv: parsed.data[39][17],
                prolif_psb_d3_value: parsed.data[40][17],
                prolif_psb_d3_stdv: parsed.data[41][17],
                img_prolif_psb_d1: "",
                img_prolif_psb_d3: "",
                round_psb_d1_aver: parsed.data[24][17],
                round_psb_d1_stdv: parsed.data[25][17],
                round_psb_d3_aver: parsed.data[26][17],
                round_psb_d3_stdv: parsed.data[27][17],
                img_morph_psb_d1: "",
                img_morph_psb_d3: "",
                aspect_psb_d1_aver: parsed.data[28][17],
                aspect_psb_d1_stdv: parsed.data[29][17],
                aspect_psb_d3_aver: parsed.data[30][17],
                aspect_psb_d3_stdv: parsed.data[31][17],
                soli_psb_d1_aver: parsed.data[32][17],
                soli_psb_d1_stdv: parsed.data[33][17],
                soli_psb_d3_aver: parsed.data[34][17],
                soli_psb_d3_stdv: parsed.data[35][17],
                /* hum_area_d1_aver: parsed.data[30][7],
                hum_area_d1_stdv: parsed.data[31][7],
                hum_area_d7_aver: parsed.data[32][7],
                hum_area_d7_stdv: parsed.data[33][7],
                hum_peri_d1_aver: parsed.data[34][7],
                hum_peri_d1_stdv: parsed.data[35][7],
                hum_peri_d7_aver: parsed.data[36][7],
                hum_peri_d7_stdv: parsed.data[37][7],
                hum_majax_d1_aver: parsed.data[38][7],
                hum_majax_d1_stdv: parsed.data[39][7],
                hum_majax_d7_aver: parsed.data[40][7],
                hum_majax_d7_stdv: parsed.data[41][7],
                hum_angle_d1_aver: parsed.data[42][7],
                hum_angle_d1_stdv: parsed.data[43][7],
                hum_angle_d7_aver: parsed.data[44][7],
                hum_angle_d7_stdv: parsed.data[45][7],
                hum_circul_d1_aver: parsed.data[46][7],
                hum_circul_d1_stdv: parsed.data[47][7],
                hum_circul_d7_aver: parsed.data[48][7],
                hum_circul_d7_stdv: parsed.data[49][7],
                hum_aspect_d1_aver: parsed.data[50][7],
                hum_aspect_d1_stdv: parsed.data[51][7],
                hum_aspect_d7_aver: parsed.data[52][7],
                hum_aspect_d7_stdv: parsed.data[53][7], */
                /* img_morph_nih_d1: "",
                img_morph_nih_d3: "", */
                /* img_morph_hum_d1: "",
                img_morph_hum_d7: "", */
                csv_file: ""
            };

            if(!fileData.bio_exp_id || !fileData.date/*  || !fileData.bioink_id */){
                setSeverity("error");
                setNotification("Mandatory fields in the file are missing.");
                setFileData({});
                setLoading(true);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
                return;
            }

            const biobaseExistsRes = await axiosClient.get("biobase/checkBiobase", {params: {bio_exp_id: fileData.bio_exp_id}})
                .then((response) => {
                    if (response.data.status === "exists") {
                        setSeverity("error");
                        setNotification("Experiment with same ID already exists.");
                        setFileData({});
                        setLoading(true);
                        sleep(3000).then(()=>{
                            window.location.reload(true);
                        });
                        return;
                    }
                })
                .catch((error) => {
                    setSeverity("error");
                    setNotification("Something went wrong.");
                    setFileData({});
                    setLoading(true);
                    sleep(3000).then(()=>{
                        window.location.reload(true);
                    });
                    return;
                });
            
            if (isNaN(fileData.vital_alg_d1_value) || isNaN(fileData.vital_alg_d1_stdv) || isNaN(fileData.vital_alg_d3_value) || isNaN(fileData.vital_alg_d3_stdv)
            || isNaN(fileData.prolif_alg_d1_value) || isNaN(fileData.prolif_alg_d1_stdv) || isNaN(fileData.prolif_alg_d3_value) || isNaN(fileData.prolif_alg_d3_stdv)
            || isNaN(fileData.round_alg_d1_aver) || isNaN(fileData.round_alg_d1_stdv) || isNaN(fileData.round_alg_d3_aver) || isNaN(fileData.round_alg_d3_stdv)
            || isNaN(fileData.aspect_alg_d1_aver) || isNaN(fileData.aspect_alg_d1_stdv) || isNaN(fileData.aspect_alg_d3_aver) || isNaN(fileData.aspect_alg_d3_stdv)
            || isNaN(fileData.soli_alg_d1_aver) || isNaN(fileData.soli_alg_d1_stdv) || isNaN(fileData.soli_alg_d3_aver) || isNaN(fileData.soli_alg_d3_stdv) /* ||
            isNaN(fileData.vital_gel_d1_value) || isNaN(fileData.vital_gel_d1_stdv) || isNaN(fileData.vital_gel_d3_value) || isNaN(fileData.vital_gel_d3_stdv)
            || isNaN(fileData.prolif_gel_d1_value) || isNaN(fileData.prolif_gel_d1_stdv) || isNaN(fileData.prolif_gel_d3_value) || isNaN(fileData.prolif_gel_d3_stdv)
            || isNaN(fileData.round_gel_d1_aver) || isNaN(fileData.round_gel_d1_stdv) || isNaN(fileData.round_gel_d3_aver) || isNaN(fileData.round_gel_d3_stdv)
            || isNaN(fileData.aspect_gel_d1_aver) || isNaN(fileData.aspect_gel_d1_stdv) || isNaN(fileData.aspect_gel_d3_aver) || isNaN(fileData.aspect_gel_d3_stdv)
            || isNaN(fileData.soli_gel_d1_aver) || isNaN(fileData.soli_gel_d1_stdv) || isNaN(fileData.soli_gel_d3_aver) || isNaN(fileData.soli_gel_d3_stdv) ||
            isNaN(fileData.vital_psb_d1_value) || isNaN(fileData.vital_psb_d1_stdv) || isNaN(fileData.vital_psb_d3_value) || isNaN(fileData.vital_psb_d3_stdv)
            || isNaN(fileData.prolif_psb_d1_value) || isNaN(fileData.prolif_psb_d1_stdv) || isNaN(fileData.prolif_psb_d3_value) || isNaN(fileData.prolif_psb_d3_stdv)
            || isNaN(fileData.round_psb_d1_aver) || isNaN(fileData.round_psb_d1_stdv) || isNaN(fileData.round_psb_d3_aver) || isNaN(fileData.round_psb_d3_stdv)
            || isNaN(fileData.aspect_psb_d1_aver) || isNaN(fileData.aspect_psb_d1_stdv) || isNaN(fileData.aspect_psb_d3_aver) || isNaN(fileData.aspect_psb_d3_stdv)
            || isNaN(fileData.soli_psb_d1_aver) || isNaN(fileData.soli_psb_d1_stdv) || isNaN(fileData.soli_psb_d3_aver) || isNaN(fileData.soli_psb_d3_stdv) */
            /* || isNaN(fileData.hum_area_d1_aver) || isNaN(fileData.hum_area_d1_stdv) || isNaN(fileData.hum_area_d7_aver) || isNaN(fileData.hum_area_d7_stdv)
            || isNaN(fileData.hum_peri_d1_aver) || isNaN(fileData.hum_peri_d1_stdv) || isNaN(fileData.hum_peri_d7_aver) || isNaN(fileData.hum_peri_d7_stdv)
            || isNaN(fileData.hum_majax_d1_aver) || isNaN(fileData.hum_majax_d1_stdv) || isNaN(fileData.hum_majax_d7_aver) || isNaN(fileData.hum_majax_d7_stdv)
            || isNaN(fileData.hum_angle_d1_aver) || isNaN(fileData.hum_angle_d1_stdv) || isNaN(fileData.hum_angle_d7_aver) || isNaN(fileData.hum_angle_d7_stdv)
            || isNaN(fileData.hum_circul_d1_aver) || isNaN(fileData.hum_circul_d1_stdv) || isNaN(fileData.hum_circul_d7_aver) || isNaN(fileData.hum_circul_d7_stdv)
            || isNaN(fileData.hum_aspect_d1_aver) || isNaN(fileData.hum_aspect_d1_stdv) || isNaN(fileData.hum_aspect_d7_aver) || isNaN(fileData.hum_aspect_d7_stdv) */) {
                setSeverity("error");
                setNotification("Please check the numerical values.");
                setFileData({});
                setLoading(true);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
                return;
            }

            setFileData(fileData);
            setBiobase(fileData);
            setReviewVisibility("visible");
            const newFile=new File([file], fileData.bio_exp_id+"."+file.name.split('.').pop()); 
            setDataFile([newFile]);
            fileData.csv_file = newFile.name;
        }
    };

    const handleDataDelete = (deletedFile) => {
        window.location.reload(true);
    };
    
    /*----------------------------------------------------------------------------------------------*/

    const [ imgVitalAlgD1, setImgVitalAlgD1 ] = useState([]);
    const [ imgVitalAlgD3, setImgVitalAlgD3 ] = useState([]);
    const [ imgProlifAlgD1, setImgProlifAlgD1 ] = useState([]);
    const [ imgProlifAlgD3, setImgProlifAlgD3 ] = useState([]);
    const [ imgMorphAlgD1, setImgMorphAlgD1 ] = useState([]);
    const [ imgMorphAlgD3, setImgMorphAlgD3 ] = useState([]);
    const [ imgVitalGelD1, setImgVitalGelD1 ] = useState([]);
    const [ imgVitalGelD3, setImgVitalGelD3 ] = useState([]);
    const [ imgProlifGelD1, setImgProlifGelD1 ] = useState([]);
    const [ imgProlifGelD3, setImgProlifGelD3 ] = useState([]);
    const [ imgMorphGelD1, setImgMorphGelD1 ] = useState([]);
    const [ imgMorphGelD3, setImgMorphGelD3 ] = useState([]);
    const [ imgVitalPsbD1, setImgVitalPsbD1 ] = useState([]);
    const [ imgVitalPsbD3, setImgVitalPsbD3 ] = useState([]);
    const [ imgProlifPsbD1, setImgProlifPsbD1 ] = useState([]);
    const [ imgProlifPsbD3, setImgProlifPsbD3 ] = useState([]);
    const [ imgMorphPsbD1, setImgMorphPsbD1 ] = useState([]);
    const [ imgMorphPsbD3, setImgMorphPsbD3 ] = useState([]);
    /* const [ imgMorphHumD1, setImgMorphHumD1 ] = useState([]);
    const [ imgMorphHumD7, setImgMorphHumD7 ] = useState([]); */

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const submit = async (e) => {
        e.preventDefault();
        if (validated()) {
            setSubmitDisabled(true);
            setLoading(true);
            try {
                
                const biobaseInput = new FormData();
                Object.keys(fileData).forEach(k => {
                    biobaseInput.append(k, fileData[k]);
                });
                if(dataFile[0]) {biobaseInput.append("images", dataFile[0]);}
                if(imgVitalAlgD1[0]) {biobaseInput.append("images", imgVitalAlgD1[0]);}
                if(imgVitalAlgD3[0]) {biobaseInput.append("images", imgVitalAlgD3[0]);}
                if(imgProlifAlgD1[0]) {biobaseInput.append("images", imgProlifAlgD1[0]);}
                if(imgProlifAlgD3[0]) {biobaseInput.append("images", imgProlifAlgD3[0]);}
                if(imgMorphAlgD1[0]) {biobaseInput.append("images", imgMorphAlgD1[0]);}
                if(imgMorphAlgD3[0]) {biobaseInput.append("images", imgMorphAlgD3[0]);}
                if(imgVitalGelD1[0]) {biobaseInput.append("images", imgVitalGelD1[0]);}
                if(imgVitalGelD3[0]) {biobaseInput.append("images", imgVitalGelD3[0]);}
                if(imgProlifGelD1[0]) {biobaseInput.append("images", imgProlifGelD1[0]);}
                if(imgProlifGelD3[0]) {biobaseInput.append("images", imgProlifGelD3[0]);}
                if(imgMorphGelD1[0]) {biobaseInput.append("images", imgMorphGelD1[0]);}
                if(imgMorphGelD3[0]) {biobaseInput.append("images", imgMorphGelD3[0]);}
                if(imgVitalPsbD1[0]) {biobaseInput.append("images", imgVitalPsbD1[0]);}
                if(imgVitalPsbD3[0]) {biobaseInput.append("images", imgVitalPsbD3[0]);}
                if(imgProlifPsbD1[0]) {biobaseInput.append("images", imgProlifPsbD1[0]);}
                if(imgProlifPsbD3[0]) {biobaseInput.append("images", imgProlifPsbD3[0]);}
                if(imgMorphPsbD1[0]) {biobaseInput.append("images", imgMorphPsbD1[0]);}
                if(imgMorphPsbD3[0]) {biobaseInput.append("images", imgMorphPsbD3[0]);}
                biobaseInput.append("bioink_id", bioink);
                /* if(imgMorphHumD1[0]) {biobaseInput.append("images", imgMorphHumD1[0]);}
                if(imgMorphHumD7[0]) {biobaseInput.append("images", imgMorphHumD7[0]);} */
                const biobaseInputRes = await axiosClient.post("biobase/createBiobase", biobaseInput);
                setLoading(false);
                setSeverity("success");
                setNotification(biobaseInputRes.data.responseMessage);
                sleep(3000).then(()=>{
                    window.location.reload(true);
                });
            } catch (err) {
                setSubmitDisabled(false);
                setLoading(false);
                setSeverity("error");
                err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
            }
        }
    };

    return (
        <>
            {
                (!userData.token) ?
                (<div>You are not authorised to access this section. Please login.</div>) :
                (
                    <Container component="main" maxWidth="md">
                        <LoadingBackdrop open={loading} />
                        <h1>Biobase Data Input</h1>
                        <div>
                        <FormControl variant="outlined"
                            margin="normal"
                            required
                            fullWidth 
                            className={classes.formControl}>
                            <InputLabel id="location_label">Bioink</InputLabel>
                            <Select
                                labelId="Bioink"
                                required
                                fullWidth
                                id="bioink"
                                value={bioink}
                                onChange={(e) => {setBioink(e.target.value)}}
                                >
                                {bioinks.map(bioink =>
                                    <MenuItem key={bioink.ink_id} value={bioink.ink_id}>{bioink.ink_id}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined"
                            margin="normal"
                            required
                            fullWidth 
                            className={classes.formControl}>
                            <InputLabel id="location_label">Cell Type</InputLabel>
                            <Select
                                labelId="Cell Type"
                                required
                                fullWidth
                                id="cellType"
                                value={cellType}
                                onChange={(e) => {setCellType(e.target.value)}}
                                >
                                {cellTypes.map(cellType =>
                                    <MenuItem key={cellType.value} value={cellType.value}>{cellType.value}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined"
                            margin="normal"
                            required
                            fullWidth 
                            className={classes.formControl}>
                            <InputLabel id="location_label">Morphology Reporter</InputLabel>
                            <Select
                                labelId="Morphology Reporter"
                                required
                                fullWidth
                                id="morphologyReporter"
                                value={morphologyReporter}
                                onChange={(e) => {setMorphologyReporter(e.target.value)}}
                                >
                                {morphologyReporters.map(morphologyReporter =>
                                    <MenuItem key={morphologyReporter.value} value={morphologyReporter.value}>{morphologyReporter.value}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <DropzoneArea
                            acceptedFiles={["application/vnd.ms-excel", ".csv"]}
                            showFileNamesInPreview={true}
                            filesLimit={1}
                            onChange={handleDataChange}
                            onDelete={handleDataDelete}
                            useChipsForPreview={true}
                            dropzoneText="Click to upload or Drag a .csv file here"
                            showAlerts={false}
                        />
                        </div>
                        <Box visibility={reviewVisibility}>
                            <hr></hr>
                            <Typography variant="h5" className={classes.title}>
                                DATA FROM YOUR FILE:
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table" >
                                    <TableBody>
                                        <StyledTableRow key="bio_exp_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bio Experiment ID:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.bio_exp_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="phd_id">
                                            <StyledTableCell component="th" scope="row">
                                                Name:
                                            </StyledTableCell>
                                            <StyledTableCell >{name ? name : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="lab_id">
                                            <StyledTableCell component="th" scope="row">
                                                Lab:
                                            </StyledTableCell>
                                            <StyledTableCell >{lab ? lab : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="date">
                                            <StyledTableCell component="th" scope="row">
                                                Date:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.date : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        {/* <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Bioink ID:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.bioink_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Cell Type:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.cellType : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="bioink_id">
                                            <StyledTableCell component="th" scope="row">
                                                Morphology Reporter:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.morphologyReporter : "----"}</StyledTableCell>
                                        </StyledTableRow> */}
                                        {/* <StyledTableRow key="compos_text">
                                            <StyledTableCell component="th" scope="row">
                                                Composition:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.compos_text : "----"}</StyledTableCell>
                                        </StyledTableRow> */}
                                        <StyledTableRow key="bio_notes">
                                            <StyledTableCell component="th" scope="row">
                                                Notes:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.bio_notes : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="printer_id">
                                            <StyledTableCell component="th" scope="row">
                                                Printer:
                                            </StyledTableCell>
                                            <StyledTableCell >{biobase ? biobase.printer_id : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <Divider />
                                <div></div>
                                <TableContainer component={Paper}>
                                <h2>Alginate(Negative Control):</h2>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="vitality">
                                            <StyledTableCell component="th" scope="row">
                                                Vitality
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"Vitality Day 1 - Image"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Alg_D1."+newImages[0].name.split('.').pop()); setImgVitalAlgD1([newFile]); fileData.img_vital_alg_d1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setImgVitalAlgD1([]); fileData.img_vital_alg_d1="";}}
                                                previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Vitality Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Alg_D3."+newImages[0].name.split('.').pop()); setImgVitalAlgD3([newFile]); fileData.img_vital_alg_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgVitalAlgD3([]); fileData.img_vital_alg_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="vitality_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_alg_d1_value+" ± "+biobase.vital_alg_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_alg_d3_value+" ± "+biobase.vital_alg_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_nih">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Alg_D1."+newImages[0].name.split('.').pop()); setImgMorphAlgD1([newFile]); fileData.img_morph_alg_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphAlgD1([]); fileData.img_morph_alg_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Alg_D3."+newImages[0].name.split('.').pop()); setImgMorphAlgD3([newFile]); fileData.img_morph_alg_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphAlgD3([]); fileData.img_morph_alg_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_round">
                                            <StyledTableCell component="th" scope="row">
                                                Roundness
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_alg_d1_aver+" ± "+biobase.round_alg_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_alg_d3_aver+" ± "+biobase.round_alg_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_alg_d1_aver+" ± "+biobase.aspect_alg_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_alg_d3_aver+" ± "+biobase.aspect_alg_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_soli">
                                            <StyledTableCell component="th" scope="row">
                                                Solidarity
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_alg_d1_aver+" ± "+biobase.soli_alg_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_alg_d3_aver+" ± "+biobase.soli_alg_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="proliferation">
                                            <StyledTableCell component="th" scope="row">
                                            Proliferation
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Alg_D1."+newImages[0].name.split('.').pop()); setImgProlifAlgD1([newFile]); fileData.img_prolif_alg_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifAlgD1([]); fileData.img_prolif_alg_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Alg_D3."+newImages[0].name.split('.').pop()); setImgProlifAlgD3([newFile]); fileData.img_prolif_alg_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifAlgD3([]); fileData.img_prolif_alg_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="proliferation_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_alg_d1_value+" ± "+biobase.prolif_alg_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_alg_d3_value+" ± "+biobase.prolif_alg_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                <h2>GelMA(Positive Control):</h2>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="vitality">
                                            <StyledTableCell component="th" scope="row">
                                                Vitality
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"Vitality Day 1 - Image"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Gel_D1."+newImages[0].name.split('.').pop()); setImgVitalGelD1([newFile]); fileData.img_vital_gel_d1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setImgVitalGelD1([]); fileData.img_vital_gel_d1="";}}
                                                previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Vitality Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Gel_D3."+newImages[0].name.split('.').pop()); setImgVitalGelD3([newFile]); fileData.img_vital_gel_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgVitalGelD3([]); fileData.img_vital_gel_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="vitality_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_gel_d1_value+" ± "+biobase.vital_gel_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_gel_d3_value+" ± "+biobase.vital_gel_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_nih">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Gel_D1."+newImages[0].name.split('.').pop()); setImgMorphGelD1([newFile]); fileData.img_morph_gel_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphGelD1([]); fileData.img_morph_gel_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Gel_D3."+newImages[0].name.split('.').pop()); setImgMorphGelD3([newFile]); fileData.img_morph_gel_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphGelD3([]); fileData.img_morph_gel_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_round">
                                            <StyledTableCell component="th" scope="row">
                                                Roundness
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_gel_d1_aver+" ± "+biobase.round_gel_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_gel_d3_aver+" ± "+biobase.round_gel_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_gel_d1_aver+" ± "+biobase.aspect_gel_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_gel_d3_aver+" ± "+biobase.aspect_gel_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_soli">
                                            <StyledTableCell component="th" scope="row">
                                                Solidarity
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_gel_d1_aver+" ± "+biobase.soli_gel_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_gel_d3_aver+" ± "+biobase.soli_gel_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="proliferation">
                                            <StyledTableCell component="th" scope="row">
                                            Proliferation
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Gel_D1."+newImages[0].name.split('.').pop()); setImgProlifGelD1([newFile]); fileData.img_prolif_gel_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifGelD1([]); fileData.img_prolif_gel_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Gel_D3."+newImages[0].name.split('.').pop()); setImgProlifGelD3([newFile]); fileData.img_prolif_gel_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifGelD3([]); fileData.img_prolif_gel_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="proliferation_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_gel_d1_value+" ± "+biobase.prolif_gel_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_gel_d3_value+" ± "+biobase.prolif_gel_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                <h2>Project Specific Bioink</h2>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="vitality">
                                            <StyledTableCell component="th" scope="row">
                                                Vitality
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                acceptedFiles={['image/*']}
                                                dropzoneText={"Vitality Day 1 - Image"}
                                                filesLimit={1}
                                                onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Psb_D1."+newImages[0].name.split('.').pop()); setImgVitalPsbD1([newFile]); fileData.img_vital_psb_d1=newFile.name;}}}
                                                onDelete={(deletedImage) => {setImgVitalPsbD1([]); fileData.img_vital_psb_d1="";}}
                                                previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Vitality Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Vital_Psb_D3."+newImages[0].name.split('.').pop()); setImgVitalPsbD3([newFile]); fileData.img_vital_psb_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgVitalPsbD3([]); fileData.img_vital_psb_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="vitality_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_psb_d1_value+" ± "+biobase.vital_psb_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.vital_psb_d3_value+" ± "+biobase.vital_psb_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_nih">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Psb_D1."+newImages[0].name.split('.').pop()); setImgMorphPsbD1([newFile]); fileData.img_morph_psb_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphPsbD1([]); fileData.img_morph_psb_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Morphology Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Psb_D3."+newImages[0].name.split('.').pop()); setImgMorphPsbD3([newFile]); fileData.img_morph_psb_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphPsbD3([]); fileData.img_morph_psb_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_round">
                                            <StyledTableCell component="th" scope="row">
                                                Roundness
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_psb_d1_aver+" ± "+biobase.round_psb_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.round_psb_d3_aver+" ± "+biobase.round_psb_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_psb_d1_aver+" ± "+biobase.aspect_psb_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.aspect_psb_d3_aver+" ± "+biobase.aspect_psb_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_soli">
                                            <StyledTableCell component="th" scope="row">
                                                Solidarity
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_psb_d1_aver+" ± "+biobase.soli_psb_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.soli_psb_d3_aver+" ± "+biobase.soli_psb_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 3</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="proliferation">
                                            <StyledTableCell component="th" scope="row">
                                            Proliferation
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Psb_D1."+newImages[0].name.split('.').pop()); setImgProlifPsbD1([newFile]); fileData.img_prolif_psb_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifPsbD1([]); fileData.img_prolif_psb_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={"Proliferation Day 3 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Prolif_Psb_D3."+newImages[0].name.split('.').pop()); setImgProlifPsbD3([newFile]); fileData.img_prolif_psb_d3=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgProlifPsbD3([]); fileData.img_prolif_psb_d3="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="proliferation_values">
                                            <StyledTableCell component="th" scope="row">
                                                Values
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_psb_d1_value+" ± "+biobase.prolif_psb_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.prolif_psb_d3_value+" ± "+biobase.prolif_psb_d3_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                    {/* <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="center">Day 1</StyledTableCell>
                                        <StyledTableCell align="center">Day 7</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key="morph_hum">
                                            <StyledTableCell component="th" scope="row">
                                            Morphology {biobase ? biobase.human_cell_type : "----"} Reporter Cells
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={biobase ? "Morphology "+biobase.human_cell_type+" Day 1 - Image" : "Morphology Human Day 1 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Hum_D1."+newImages[0].name.split('.').pop()); setImgMorphHumD1([newFile]); fileData.img_morph_hum_d1=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphHumD1([]); fileData.img_morph_hum_d1="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <DropzoneArea
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={biobase ? "Morphology "+biobase.human_cell_type+" Day 7 - Image" : "Morphology Human Day 7 - Image"}
                                                    filesLimit={1}
                                                    onChange={(newImages) => {if(newImages[0]){const newFile=new File([newImages[0]], "IMG_Morph_Hum_D7."+newImages[0].name.split('.').pop()); setImgMorphHumD7([newFile]); fileData.img_morph_hum_d7=newFile.name;}}}
                                                    onDelete={(deletedImage) => {setImgMorphHumD7([]); fileData.img_morph_hum_d7="";}}
                                                    previewGridProps={gridProps}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_area">
                                            <StyledTableCell component="th" scope="row">
                                                Area (µm²)
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_area_d1_aver+" ± "+biobase.hum_area_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_area_d7_aver+" ± "+biobase.hum_area_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_perimeter">
                                            <StyledTableCell component="th" scope="row">
                                                Perimeter (µm)
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_peri_d1_aver+" ± "+biobase.hum_peri_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_peri_d7_aver+" ± "+biobase.hum_peri_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_majax">
                                            <StyledTableCell component="th" scope="row">
                                                Major Axis (µm)
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_majax_d1_aver+" ± "+biobase.hum_majax_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_majax_d7_aver+" ± "+biobase.hum_majax_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_angle">
                                            <StyledTableCell component="th" scope="row">
                                                Angle (°)
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_angle_d1_aver+" ± "+biobase.hum_angle_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_angle_d7_aver+" ± "+biobase.hum_angle_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_circul">
                                            <StyledTableCell component="th" scope="row">
                                                Circularity
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_circul_d1_aver+" ± "+biobase.hum_circul_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_circul_d7_aver+" ± "+biobase.hum_circul_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key="morph_hum_aspect">
                                            <StyledTableCell component="th" scope="row">
                                                Aspect Ratio
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_aspect_d1_aver+" ± "+biobase.hum_aspect_d1_stdv : "----"}</StyledTableCell>
                                            <StyledTableCell align="center">{biobase ? biobase.hum_aspect_d7_aver+" ± "+biobase.hum_aspect_d7_stdv : "----"}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody> */}
                            </TableContainer>
                            <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                disabled={submitDisabled}
                            >
                                Save to Database
                            </Button>
                            </form>                        
                        </Box>
                        {notification && (
                            <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
                        )}
                    </Container>

                )
            }
        </>
    )
}
