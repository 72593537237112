import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _ from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'ink_exp_id',
        label: 'Experiment ID'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'ink_id',
        label: 'Ink ID'
    },
];

export default function RheobaseEvaluation() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allRheobaseButtonVariant, setAllRheobaseButtonVariant ] = useState("outlined");
    const [ myRheobaseButtonVariant, setMyRheobaseButtonVariant ] = useState("outlined");
    const [ sopRheobaseButtonVariant, setSopRheobaseButtonVariant ] = useState("outlined");
    const [ allRheobaseButtonSize, setAllRheobaseButtonSize ] = useState();
    const [ myRheobaseButtonSize, setMyRheobaseButtonSize ] = useState();
    const [ sopRheobaseButtonSize, setSopRheobaseButtonSize ] = useState();

    const [ compareRheobases, setCompareRheobases ] = useState([]);
    const [ compareButton, setCompareButton ] = useState(false);
    const [ compareButtonText, setCompareButtonText ] = useState("Compare this...");

    const [ allRheobases, setAllRheobases ] = useState([]);
    const [ rheobases, setRheobases ] = useState([]);
    const [ rheobasesLoaded, setRheobasesLoaded ] = useState(false);
    let searchKey = "";
    const [ sortBy, setSortBy ] = useState("");
  
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadAllRheobases();
    }, []);

    const loadAllRheobases = async () => {
        try {
            setLoading(true);
            const rheobasesRes = await axiosClient.get("rheobase/getAll");
            setRheobases(rheobasesRes.data);
            setAllRheobases(rheobasesRes.data);
            setRheobasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setAllRheobaseButtonVariant("contained");
        setAllRheobaseButtonSize("large");
        setMyRheobaseButtonVariant("outlined");
        setMyRheobaseButtonSize("medium");
        setSopRheobaseButtonVariant("outlined");
        setSopRheobaseButtonSize("medium");
    };

    const loadMyRheobases = async () => {
        try {
            setLoading(true);
            const rheobasesRes = await axiosClient.get("rheobase/getMyRheobases");
            setRheobases(rheobasesRes.data);
            setAllRheobases(rheobasesRes.data);
            setRheobasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setMyRheobaseButtonVariant("contained");
        setMyRheobaseButtonSize("large");
        setAllRheobaseButtonVariant("outlined");
        setAllRheobaseButtonSize("medium");
        setSopRheobaseButtonVariant("outlined");
        setSopRheobaseButtonSize("medium");
    }

    const loadSopRheobases = async () => {
        try {
            setLoading(true);
            const rheobasesRes = await axiosClient.get("rheobase/getSopRheobases");
            setRheobases(rheobasesRes.data);
            setAllRheobases(rheobasesRes.data);
            setRheobasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setSopRheobaseButtonVariant("contained");
        setSopRheobaseButtonSize("large");
        setAllRheobaseButtonVariant("outlined");
        setAllRheobaseButtonSize("medium");
        setMyRheobaseButtonVariant("outlined");
        setMyRheobaseButtonSize("medium");
    }

    const handleListItemClick = (e, inkExpId, category) => {
        if (category === "SOP") {
            return;
        }
        localStorage.setItem("ink_exp_id", inkExpId);
        history.push("/rheobasePlot");
    };

    let compareRef = useRef();

    const filterRheobases = () => {
        const results = [];
        allRheobases.forEach(rheobase => {
            if (rheobase.ink_exp_id.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.name.toUpperCase().includes(searchKey.toUpperCase()) || 
            rheobase.date.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.ink_id.toUpperCase().includes(searchKey.toUpperCase()) || 
            rheobase.location.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.device.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(rheobase);
            }
        });
        setRheobases(results);
    };
    
    const sortRheobases = (sortKey) => {
        setRheobases(_.sortBy(rheobases, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Rheobase Data Evaluation<br></br>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        justify="center"
                        startIcon={<CompareArrowsIcon />}
                        onClick={(e) => history.push("/rheobaseComparison")}
                    >
                        Rheobase Comparison
                    </Button>
                    </Typography>
                    {/* <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        <Button variant={allRheobaseButtonVariant} color="default" size={allRheobaseButtonSize} onClick={() => history.push("/rheobaseComparison")}>
                            Rheobase Comparison
                        </Button>
                    </Typography> */}
                    <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                        <ColorButton variant={allRheobaseButtonVariant} color="default" size={allRheobaseButtonSize} onClick={() => loadAllRheobases()}>
                            All Rheobase Experiments
                        </ColorButton>
                        </Grid>
                        <Grid item>
                        <ColorButton variant={myRheobaseButtonVariant} color="default" size={myRheobaseButtonSize} onClick={() => loadMyRheobases()}>
                            My Rheobase Experiments
                        </ColorButton>
                        </Grid>
                        {
                           (userData.role==="admin" || userData.role==="sopuser") ?
                           (
                            <Grid item>
                            <ColorButton variant={sopRheobaseButtonVariant} color="default" size={sopRheobaseButtonSize} onClick={() => loadSopRheobases()}>
                                SOP Rheobase Experiments
                            </ColorButton>
                            </Grid>
                           ) : (<div></div>)
                        }
                    </Grid>
                    </div>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(rheobasesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="rheobase_search"
                                    label="Search Rheobase"
                                    name="rheobase_search"
                                    onChange={(e) => {searchKey=e.target.value; filterRheobases();}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="rheobase_sort"
                                    name="rheobase_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortRheobases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(rheobases.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {rheobases.map(rheobase => (
                        <ListItem button onClick={(e) => handleListItemClick(e, rheobase.ink_exp_id, rheobase.category)} key={rheobase.ink_exp_id}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={rheobase.ink_exp_id}
                                secondary={`Name: ${rheobase.name} || Date: ${rheobase.date} || InkID: ${rheobase.ink_id} || Location: ${rheobase.location} || Device: ${rheobase.device}`} 
                            />
                            {/* <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleCompareClick(e, rheobase.ink_exp_id)}>
                                    {compareButtonText}
                                </Button>
                            </ListItemSecondaryAction> */}
                        </ListItem>
                    ))}
                </List>
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
