import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _, { methods } from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import LabelIcon from '@material-ui/icons/Label';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import NotificationSnackbar from "../feedback/NotificationSnackbar";


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'ink_exp_id',
        label: 'Experiment ID'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'ink_id',
        label: 'Ink ID'
    },
];

export default function RheobaseUpdate() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allRheobases, setAllRheobases ] = useState([]);
    const [ rheobases, setRheobases ] = useState([]);
    const [ rheobasesLoaded, setRheobasesLoaded ] = useState(false);
    /* let searchKey = ""; */
    const [ sortBy, setSortBy ] = useState("");
  
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
    const [ deleteDialogTitle, setDeleteDialogTitle ] = useState("Delete");
    const [ deleteRheobase, setDeleteRheobase ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (userData.role === "admin") {
            loadAllRheobases();
        } else {
            loadMyRheobases();
        }
    }, []);

    const loadMyRheobases = async () => {
        try {
            setLoading(true);
            const rheobasesRes = await axiosClient.get("rheobase/getMyRheobases");
            setRheobases(rheobasesRes.data);
            setAllRheobases(rheobasesRes.data);
            setRheobasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    };

    const loadAllRheobases = async () => {
        try {
            setLoading(true);
            const rheobasesRes = await axiosClient.get("rheobase/getAll");
            setRheobases(rheobasesRes.data);
            setAllRheobases(rheobasesRes.data);
            setRheobasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    };

    const handleEditClick = (e, mId) => {
        localStorage.setItem("edit_rheobase_id", mId);
        history.push("/rheobaseEdit");
    };

    const handleDeleteClick = (e, rheobase) => {
        setDeleteDialogTitle("Delete experiment with ID: " + rheobase.ink_exp_id + " ?")
        setOpenDeleteDialog(true);
        setDeleteRheobase(rheobase);
    };

    let compareRef = useRef();

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    };
    
    const deleteRheobaseConfirm = async (e) => {
        setLoading(true);
        try {
            handleCloseDeleteDialog();
            const rheobaseDeleteRes = await axiosClient.delete("rheobase/deleteRheobase", {params: {ink_exp_id_id: deleteRheobase.id}});
            setSeverity("success");
            setNotification(rheobaseDeleteRes.data.responseMessage);
            sleep(2000).then(()=>{
                history.push("/dashboard");
            });
        } catch (error) {
            setSeverity("error");
            setNotification("Something went wrong.");
        }
        setLoading(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const filterRheobases = (searchKey) => {
        const results = [];
        allRheobases.forEach(rheobase => {
            if (rheobase.rheo_id.toUpperCase().includes(searchKey.toUpperCase()) || rheobase.category.toUpperCase().includes(searchKey.toUpperCase()) ||
            rheobase.composition.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(rheobase);
            }
        });
        setRheobases(results);
    };
    
    const sortRheobases = (sortKey) => {
        setRheobases(_.sortBy(rheobases, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Update Your Rheo Data
                    </Typography>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(rheobasesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="rheobases_search"
                                    label="Search Rheobases"
                                    name="rheobases_search"
                                    onChange={(e) => {/* searchKey=e.target.value; */ filterRheobases(e.target.value);}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="rheobases_sort"
                                    name="rheobases_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortRheobases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(rheobases.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {rheobases.map(rheobase => (
                        <ListItem key={rheobase.availableRheobase}>
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                 primary={rheobase.ink_exp_id}
                                 secondary={`Name: ${rheobase.name} || Date: ${rheobase.date} || InkID: ${rheobase.ink_id} || Location: ${rheobase.location} || Device: ${rheobase.device}`} 
                             />
                            <ListItemSecondaryAction>
                               {/*  <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => handleEditClick(e, rheobase.ink_exp_id)}>
                                    Edit
                                </Button> */}
                                <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, rheobase)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                </Container>
                <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{deleteDialogTitle}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The experiment data will be permanently deleted. Are you sure?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={deleteRheobaseConfirm} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                        No
                    </Button>
                    </DialogActions>
                </Dialog>
            </main>
        </React.Fragment>
        )
        }
        
        {notification && (
                <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        </>
    );
}
